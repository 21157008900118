import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'

import encode from '../../services/encode'

export function Slot({
  team,
  location,
  resource,
  service,
  financier,
  day,
  time,
  price,
  currency,
  flow,
  whatsapp,
  reservation,
  mercadopago,
  cash,
}) {
  const { i18n } = useTranslation()

  const payload = {
    teamID: team,
    locationID: location,
    resourceID: resource,
    serviceKey: service,
    financierKey: financier,
    day: day,
    time: time,
    price: price > 1 ? price : undefined,
    currency: currency,
    flow: flow,
    whatsapp: whatsapp,
    reservation: reservation || 'always',
    mercadopago: mercadopago ?? true,
    cash: cash ?? true,
  }
  const code = encode(payload)

  return (
    <Link
      to={`/turnos/checkout/${code}`}
      className="
        text-sm opacity-80 rounded-lg border border-gray-300 font-medium px-3 py-2 leading-none
        hover:text-green-600 hover:bg-green-50 hover:border hover:border-green-200
        active:bg-green-50 active:text-green-600 active:border-green-200
      "
    >{time}</Link>
  )
}
