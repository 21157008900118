import { useTranslation } from "react-i18next";

const Email = ({ event, dateLabel }) => {
  const { t } = useTranslation()

  const payload = {
    date: dateLabel,
    day: event.day,
    time: event.time,
    range: event.range,
    whatsapp: event.whatsapp,
    consumer: event.consumer.label,
    phone: event.consumer.phone,
    email: event.consumer.email,
    identification: event.consumer.identification,
    resource: event.resource.label,
    service: event.service.label,
    address: event?.location?.address
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: t(
          `event_flow_${event.flow}_html`,
          `<div class="md:my-3 p-4 text-xs bg-yellow-100 md:border md:border-yellow-500 md:rounded-md text-yellow-700">El centro se va comunicar dentro de las proximas 48hs para terminar de confirmar el turno</div>`,
          payload
        )
      }}
    />
  )
}

export default Email
