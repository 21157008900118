/* eslint-disable */
import { useEffect, useState, useRef } from "react";

const useScroll = (callback) => {
  const $bottom = useRef(null);

  useEffect(() => {
    /*
      Si activamos esto no tenemos ese molesto scroll al entrar al componente por
      el sticky top(en chrome). Si lo sacamos mantenmos el scroll entre rutas.
    */
    try {
      window.scrollTo(0, 0);
    } catch {}
  }, []);

  useEffect(() => {
    const $element = $bottom && $bottom.current ? $bottom.current : null;

    if ("IntersectionObserver" in window && $element) {
      const handler = (changes, observer) => {
        changes.forEach((change) => {
          if (change.intersectionRatio > 0 && callback) callback();
        });
      };

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver(handler, options);

      observer.observe($element);

      return () => {
        observer.unobserve($element);
        observer.disconnect();
      };
    }
  }, [$bottom, callback]);

  return $bottom;
};

export default useScroll;
/* eslint-enable */
