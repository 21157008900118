const decode = string =>
  JSON.parse(
    atob(
      decodeURIComponent(
        string
      )
    )
  )

export default decode
