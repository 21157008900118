const encode = payload => 
  encodeURIComponent(
    btoa(
      JSON.stringify(
        payload
      )
    )
  )

export default encode
