import Availability from './Availability/Availability'
import Profile from './Profile'

const Card = props => {
  const item = props.item

  if (!props.item) return null

  return (
    <div className="sm:rounded-lg bg-white">
      <Profile
        className="p-4"
        profile={item}
        remote={props.filters.remote}
        atHome={props.filters.atHome}
      />
      <Availability
        filters={{
          ...props.filters,
          location: item.location?.id,
          resource: item.resource?.id,
          service: item.service?.id,
          financier: item.financier?.id,
          coords: item.location?.coords,
          team: item.team?.id,
        }}
        price={item.price}
        currency={item.currency}
        className="mt-1 px-4 pb-4"
      />
    </div>
  )
}

export default Card
