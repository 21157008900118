import { Link } from '@reach/router'
import { useTranslation } from "react-i18next";
import kebabCase from 'lodash/kebabCase'

const Profile = props => {
  const { t } = useTranslation()

  const profile = props.profile
  const options = JSON.parse(
    JSON.stringify({
      service: props.profile?.service?.id?.replace(/pms_specialties:|pms_practices:/g, ''),
      financier: props.profile?.financier?.id?.replace(/pms_financiers:/g, ''),
      remote: props.remote,
      atHome: props.atHome,
      location: props.profile?.location?.id,
    })
  )
  const params = new URLSearchParams(options).toString()

  if (!profile.id) return null
  const link = [
    `/profesional/${kebabCase(profile.label)}/${profile.id.replace('resources/', '')}`,
    params
  ].filter(Boolean).join('?')

  return (
    <Link to={link} className={`flex gap-x-3 sm:gap-x-4 lg:gap-x-6 xl:gap-x-8 ${props.className || ''}`}>
      <div className="flex flex-col justify-end items-center">
        <div
          className="
            bg-gray-200
          "
          style={{
            borderRadius: '20px',
            backgroundImage: profile.image ? `url(${profile.image})` : undefined,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: 104,
            height: 116,
          }}
        />

        {profile.score >= 0 && (
          <div className="relative -mt-2 rounded-full px-4 py-1 bg-[#7678F3] flex items-center gap-x-1">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="text-[#FDCD00] h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </div>
            <div className="text-xs text-white font-medium">
              {Number(profile.score).toLocaleString()}
            </div>
          </div>
        )}
      </div>
      <div className="grow">
        <div className="flex items-center justify-between">
          <div className="
            text-secondary font-medium grow
          ">{profile.label}</div>
          {profile.distance >= 0 && (
            <div className="text-xs opacity-[0.64]">{profile.distance} km</div>
          )}
        </div>
        <div className="text-[13px] opacity-60 font-medium mb-2 capitalize">
          {profile.specialties.map(specialty => t(specialty.id, specialty.label)).join(' | ')}
        </div>
        {profile.location.address && (
          <div className="text-[13px] opacity-[0.84] font-medium">
            {profile.location.address}
          </div>
        )}
        {profile.price > 0 && (
          <div className="text-green-500 font-medium mt-1" data-price={profile.price} data-currency={t(`currency_symbol_${profile.currency}`, '$')}>
            {t(`currency_symbol_${profile.currency}`, '$')} {profile.price.toLocaleString()}
          </div>
        )}
      </div>
    </Link>
  )
}

export default Profile
