import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'

import Loader from '../../Loader'
import Categories from './Categories'
import Zones from './Zones'
import Results from './Results'
import useCMS from './useCMS'
import usePosition from './usePosition'
import useStore from './useStore'

const Wizard = props => {
  const cms = useCMS()
  const { t } = useTranslation()
  const position = usePosition()
  const [coords, update, type] = useStore(state => [state.coords, state.update, state.type])
  const location = useLocation()

  const isCurrent = location.pathname.includes('/current')
  const isInput = location.pathname.includes('/input')

  useEffect(() => {
    const isCurrent = location.pathname.includes('/current')

    if (isCurrent) {
      position.get(coords => update({ coords, type: 'current', address: t('my_position', 'Mi ubicacion') }))
    } else if (!isCurrent && type === 'current') {
      update({ coords: undefined, address: undefined, type: undefined })
    } else if (!isInput && type === 'input') {
      update({ coords: undefined, address: undefined, type: undefined })
    }
  }, [location.pathname, type, cms?.zones?.current])

  return !cms ? (
    <Loader className="mt-7 flex flex-col items-center" />
  ) : !cms.country?.slug ? (
    <Zones className="bg-white md:m-4 md:rounded-lg p-6 h-screen md:h-[100%!important]" />
  ) : !cms.categories?.current || cms.categories?.current?.childrens ? (
    <Categories className="bg-white md:m-4 md:rounded-lg p-6 h-screen md:h-[100%!important]" />
  ) : !coords && !cms?.zones?.current?.coords && !isCurrent && !isInput ? (
    <Zones className="bg-white md:m-4 md:rounded-lg p-6 h-screen md:h-[100%!important]" />
  ) : (
    <Results
      financier={{ id: 'wiri' }}
    />
  )
}

export default Wizard
