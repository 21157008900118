import { useEffect } from 'react'
import { useNavigate, useLocation } from '@reach/router'

import useStore from '../Events/Wizard/useStore'
import Loader from '../Loader'

const {
  CMS_SPECIALTIES_PRACTICES_URL = 'https://wiri.la/cms/default-specialties-practices'
} = process.env

export function MigrationResults(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const update = useStore(state => state.update)

  useEffect(() => {
    (async () => {
      if (!props.specialty) return
      const params = new URLSearchParams(location.search)
      const _location = params.get('location')
      const rows = await fetch(CMS_SPECIALTIES_PRACTICES_URL).then(res => res.json())
      const row = rows.find(item => item.specialty === props.specialty)
      const service = `${props.specialty}/${row?.practice || 'consulta'}`
      if (_location === 'current') {
        navigate(`/ar/current/turnos/${props.specialty}/${row?.practice || 'consulta'}`, { replace: true })
      } else if (_location) {
        window.places.getDetails({ placeId: _location }, result => {
          if (!result.geometry?.location) return

          let { lat, lng } = result.geometry.location
          if (lat?.constructor === Function) {
            lat = lat()
          }
          if (lng?.constructor === Function) {
            lng = lng()
          }
          const coords = [lat, lng].filter(Boolean).join(',')
          update({ coords, address: result.formatted_address, type: 'input' })
        })
        navigate(`/ar/input/turnos/${props.specialty}/${row?.practice || 'consulta'}`, { replace: true })
      }
    })()

  }, [props.specialty, location.search])

  return (
    <Loader className="flex items-center justify-center h-screen" />
  )
}
