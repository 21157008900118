import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from '@reach/router'

import Loader from '../../Loader'

export function Services(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { locations, services, financiers, selected, price } = props

  const onChange = ({ target: { name, value } }) => {
    const params = new URLSearchParams(location.search)
    params.set(name, value?.replace(/pms_specialties:|pms_financiers:|pms_practices:/g, ''))
    navigate(`${location.pathname}?${params.toString()}`)
  }

  if (!selected) return null

  return (
    <div className="bg-white py-2 px-2 mt-4 sm:rounded-lg" id="services">
      <div className="mb-2">
        <div className="px-3 font-bold text-sm">{t('location')}</div>
        <select
          name="location"
          value={selected?.location?.id}
          onChange={onChange}
          className="w-full py-0 px-3 border-0 focus:outline-0 focus:[box-shadow:none] text-sm"
        >
          {locations.map(location =>
            <option
              key={location.id}
              value={location.id}
              disabled={
                (selected.service?.id && !location?.services[selected.service?.id]) ||
                (selected.financier?.id && !location?.financiers[selected.financier?.id])
              }
            >{location.address}</option>
          )}
        </select>
        {selected.service?.id && selected.location?.id && !selected.location?.services?.[selected.service?.id] && (
          <div className="px-3 text-sm text-red-700">
            {t('location_not_service_warning', 'Este lugar no atiende el servicio seleccionado', { service: t(selected.service?.id, selected.service?.label) })}
          </div>
        )}
        {selected.financier?.id && selected.location?.id && !selected.location?.financiers?.[selected.financier?.id] && (
          <div className="px-3 text-sm text-red-700">
            {t('location_not_financier_warning', 'Este lugar no atiende por la cobertura seleccionada', { financier: t(selected.financier?.id, selected.financier?.label) })}
          </div>
        )}
      </div>
      <div className="mb-2">
        <div className="px-3 font-bold text-sm">{t('service')}</div>
        <select
          name="service"
          value={selected?.service?.id}
          onChange={onChange}
          className="w-full py-0 px-3 border-0 focus:outline-0 focus:[box-shadow:none] text-sm"
        >
          {services.map(service =>
            <option
              key={service.id}
              value={service.id}
              disabled={
                (selected.location?.id && !service?.locations[selected.location?.id]) ||
                (selected.financier?.id && !service?.financiers[selected.financier?.id]) ||
                (/wiri/.test(selected.financier?.id) && !service.valid)
              }
            >{t(service.id, service.label)}</option>
          )}
        </select>
        {selected.location?.id && selected.service?.id && !selected.service?.locations?.[selected.location?.id] && (
          <div className="px-3 text-sm text-red-700">
            {t('service_not_location_warning', 'Este servicio no se atiende en el lugar seleccionado', { location: selected.location?.address })}
          </div>
        )}
        {(
          (
            selected.financier?.id && selected.service?.id && !selected.service?.financiers?.[selected.financier?.id]
          ) || (
            /wiri/.test(selected.financier?.id) && !selected.service?.valid
          )
        ) && (
          <div className="px-3 text-sm text-red-700">
            {t('service_not_financier_warning', 'Este servicio no se atiende por la cobertura seleccionada', { financier: t(selected.financier?.id, selected.financier?.label) })}
          </div>
        )}
      </div>
      <div className="mb-2">
        <div className="px-3 font-bold text-sm">{t('financier')}</div>
        <select
          name="financier"
          value={selected?.financier?.id}
          onChange={onChange}
          className="w-full py-0 px-3 border-0 focus:outline-0 focus:[box-shadow:none] text-sm"
        >
          {financiers.map(financier =>
            <option
              key={financier.id}
              value={financier.id}
              disabled={
                (selected.location?.id && !financier?.locations[selected.location?.id]) ||
                (selected.service?.id && !financier?.services[selected.service?.id])
              }
            >{t(financier.id, financier.label)}</option>
          )}
        </select>
        {selected.location?.id && selected.financier?.id && !selected.financier?.locations?.[selected.location?.id] && (
          <div className="px-3 text-sm text-red-700">
            {t('financier_not_location_warning', 'Esta cobertura no se atiende en el lugar seleccionado', { location: selected.location?.address })}
          </div>
        )}
        {selected.service?.id && selected.financier?.id && !selected.financier?.services?.[selected.service?.id] && (
          <div className="px-3 text-sm text-red-700">
            {t('financier_not_service_warning', 'Este cobertura no cubre el servicio seleccionado', { service: t(selected.service?.id, selected.service?.label) })}
          </div>
        )}
      </div>
      {price.isFetching ? (
        <Loader
          className="-mx-2 flex items-center justify-center border-t pt-2 text-center"
        />
      ) : price.data?.price > 0 ? (
        <div className="-mx-2 border-t pt-2 px-5 flex gap-x-1 justify-between">
          <div className="font-medium text-sm text-secondary/80">{t('price', 'Precio')}</div>
          <div className="font-medium text-sm text-[#6BAF66]" data-price={price?.data?.price} data-currency={t(`currency_symbol_${price?.data?.currency}`, '$')}>
            {t(`currency_symbol_${price?.data.currency}`, '$')} {price.data?.price?.toLocaleString()}
          </div>
        </div>
      ) : price.data && price.isFetched && (
        <div className="-mx-2 border-t pt-2 px-5 sm:flex sm:gap-x-1 sm:justify-between sm:gap-x-3">
          <div className="hidden sm:block font-medium text-sm text-secondary/80">{t('price', 'Precio')}</div>
          <div className="font-medium text-sm text-[#6BAF66]">
            {t('missing_price_resource_profile', 'No hay un precio establecido. De todas formas podes tomar el turno')}
          </div>
        </div>
      )}
    </div>
  )
}
