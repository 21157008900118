import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import uniq from 'lodash/uniq'

import Loader from '../../../Loader'
import Day from './Day'

import useStore from '../useStore'
import useLocalStore from './useStore'

const Availability = props => {
  const { t } = useTranslation()
  const startsAt = useStore(state => state.date)
  const timezone = useLocalStore(state => state.timezone)
  const [days, setDays] = useState([])
  const [max, setMax] = useState()
  const [loading, setLoading] = useState(true)

  const add = () => {
    const last = [...days, max].sort().reverse()[0] || new Date().toLocaleDateString('fr-ca')
    const datetime = new Date(`${last}T00:00`).getTime() + 24 * 60 * 60 * 1000
    const next = new Date(datetime).toLocaleDateString('fr-ca')
    setLoading(true)
    setDays(uniq([...days, next]).sort())
  }

  const onSuccess = data => {
    const max = [
      ...Object.keys(data?.slots || {}),
      ...days
    ].filter(Boolean).sort().reverse()[0]
    setMax(max)
    setLoading(false)
  }

  useEffect(() => {
    const days = {}
    try {
      const day = startsAt.toLocaleDateString('fr-ca')
      days[day] = true
    } catch {}
    setDays(Object.keys(days).sort())
  }, [startsAt])

  return (
    <div className={props.className}>
      <div className="flex flex-col gap-y-1 mt-4">
        {days.map(day => (
          <Day
            key={day}
            onSuccess={onSuccess}
            price={props.price}
            currency={props.currency}
            filters={{ ...props.filters, facets: false, startsAt: day }} />
        ))}
      </div>

      <div className="flex items-center justify-center">
        <div className="flex items-center gap-x-1">
          {loading && (
            <Loader />
          )}
          <button
            onClick={add}
            className="
              whitespace-nowrap
              text-[12px] opacity-60 px-3 -ml-2
              hover:opacity-80
              active:opacity-100 active:text-dark
            "
          >{t('show_more_days', 'Mostrar mas dias')}</button>
        </div>

        {timezone === 'local' && (
          <div className="text-[11px] text-orange-500 grow text-right">
            {t('slots_location_timezone', 'Los horarios corresponden a la hora local del consultorio')}
          </div>
        )}
      </div>
    </div>
  )
}

export default Availability
