import { useState } from 'react'
import { DayPicker } from 'react-day-picker'
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { useTranslation } from "react-i18next";

const locales = { es, en }

export function Picker(props) {
  const { i18n, t } = useTranslation()
  const [show, setShow] = useState()
  const locale = locales[i18n.language?.split('-')?.[0]] || locales.es

  const date = props.date || new Date()

  const onDayClick = date => {
    setShow(false)
    if (props.onDayClick) props.onDayClick(date)
  }

  return (
    <div className="relative">
      <button
        onClick={() => setShow(!show)}
        className="
        flex gap-x-1
        text-[13px] rounded-[18px] text-ellipsis overflow-hidden whitespace-nowrap border border-[#70707026] px-3 py-1 hover:bg-gray-50 active:bg-gray-100
      ">
        {props.label && (
          <span className="font-medium sm:hidden sm:inline">{props.label}</span>
        )}
        {props.short ? (
          <span>{i18n.formatDate(date, 'eee d MMM')}</span>
        ) : (
          <span>{i18n.formatDate(date, 'eee d MMM, yyyy')}</span>
        )}

      </button>
      {show && (
        <DayPicker
          className="
            bg-white z-[10]
            fixed top-0 left-0 bottom-0 left-0 w-screen flex flex-col items-center pt-10
            md:absolute md:top-auto md:left-auto md:bottom-auto md:left-auto md:w-auto md:pt-4 md:p-4 md:mt-1 md:border md:border-gray-300 md:rounded md:shadow-lg
            md:-left-10
          "
          onDayClick={onDayClick}
          selectedDays={date}
          locale={locale}
        />
      )}
    </div>
  )
}
