import { useTranslation } from 'react-i18next'

export function Day({ day, selected, onClick }) {
  const { i18n } = useTranslation()

  return (
    <button
      onClick={onClick}
      className={`
        flex flex-col items-center justify-center h-[71px] w-[65px] min-h-[71px] min-w-[65px] border rounded-lg
        ${selected ? 'text-primary bg-primary/10 border-primary/10' : 'text-secondary/60 hover:text-primary hover:border-primary/30 active:bg-primary/10'}
      `}
    >
      <div className="text-[13px] font-medium capitalize">{i18n.formatDate(`${day}T00:00`, 'eee')}</div>
      <div className="font-medium">{i18n.formatDate(`${day}T00:00`, 'd')}</div>
      <div className="text-xs capitalize">{i18n.formatDate(`${day}T00:00`, 'MMM')}</div>
    </button>
  )
}
