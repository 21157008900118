import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query'

import 'react-day-picker/dist/style.css';
import "./i18n";
import './index.scss';
import './window'
import App from './App';
import reportWebVitals from './reportWebVitals';

const {
  REACT_APP_MAINTENANCE,
} = process.env;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    {REACT_APP_MAINTENANCE ? (
      <div className="h-screen flex flex-col items-center justify-center p-3">
        <h2 className="text-lg font-bold">Interrupcion temporal</h2>
        <p>Nuestro equipo está trabajando para normalizar el sistema lo antes posible</p>
      </div>
    ) : (
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback="">
          <App />
        </React.Suspense>
      </QueryClientProvider>
    )}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
