const { REACT_APP_API_URL } = process.env

const request = async (path = '/', method, body) => {
  const options = {
    method: method || 'GET',
    headers: {'Content-Type': 'application/json'},
  }

  if (body?.constructor === Object || body?.constructor === Array) {
    options.body = JSON.stringify(body)
    options.method = method || 'POST'
  }

  // console.log(`${REACT_APP_API_URL}${path}`, options)

  const res = await fetch(`${REACT_APP_API_URL}${path}`, options)

  if (!res.ok) return

  return res.json()
}

const api = (path, params) => request(path, null, params)

api.post = (path, body) => request(path, 'POST', body)
api.put = (path, body) => request(path, 'PUT', body)
api.get = (path, params) => request(path, 'GET', params)

export default api
