import { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import { useTranslation } from "react-i18next";

import encode from '../../../services/encode'
import useStore from './useStore'

const Range = props => {
  const update = useStore(state => state.update)
  const [link, setLink] = useState()
  const { t } = useTranslation()

  const track = e => {
    try {
      window.gtag("event", "slot", {
        currency: props.slot?.currency,
        value: props.slot?.price,
        resource: props.slot?.resource,
        day: props.slot?.day,
        range: props.slot?.range,
        flow: props.slot?.flow,
      });
    } catch {}
  }

  useEffect(() => {
    let timezone
    if (props.slot) try {
      const offset = props.slot.offset
      const diff = offset !== (new Date().getTimezoneOffset() * -1)
      if (diff) {
        timezone = 'local'
      }
    } catch {}

    const payload = {
      teamID: props.slot.team,
      locationID: props.slot.location,
      resourceID: props.slot.resource,
      serviceKey: props.slot.service,
      financierKey: props.slot.financier,
      day: props.slot.day,
      range: props.slot.range,
      price: props.slot.price,
      currency: props.slot.currency,
      flow: props.slot.flow,
      whatsapp: props.slot.whatsapp,
    }

    const code = encode(payload)

    update({ timezone })
    setLink(`/turnos/checkout/${code}`)
  }, [props.slot])


  if (!props.slot.range || !link) return null

  const [startsAt, endsAt] = props.slot.range.split('-')

  return (
    <Link
      to={link}
      onClick={track}
      className="
        text-sm opacity-80 rounded-lg border border-gray-300 font-medium px-3 py-2 leading-none
        hover:text-green-600 hover:bg-green-50 hover:border hover:border-green-200
        active:bg-green-50 active:text-green-600 active:border-green-200
      "
      dangerouslySetInnerHTML={{
        __html: t('range_between', `Entre las <span class="text-black">${startsAt}</span> y las <span class="text-black">${endsAt}</span>`, { startsAt, endsAt })
      }}
    />
  )
}

export default Range
