import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { formatDate } from '../i18n'
import Loader from '../Loader'

const {
  REACT_APP_API_URL,
  REACT_APP_MEET_URL
} = process.env

const Event = (props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const cancel = useMutation(
    () => fetch(`${REACT_APP_API_URL}/events/${props.eventID}/${props.token}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.json()),
    {
      onMutate: (data) => {
        queryClient.setQueryData(['events', props.eventID, props.token], () => ({ ...event.data, status: 'cancelled' }))
      },
      onSuccess: (data) => {},
      onError: () => {}
    }
  )

  const id = `events/${props.eventID}`.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim()

  const event = useQuery(
    ['events', props.eventID, props.token],
    () => fetch(`${REACT_APP_API_URL}/${id}/${props.token}`).then(res => res.json()),
    {
      enabled: !!(props.eventID && props.token),
      initialData: {}
    }
  )

  if (event.isError) return (
    <div className="bg-yellow-200 text-yellow-900 p-3 rounded mt-7 text-center max-w-md mx-auto">{t('error_loading_message', 'Ocurrio un error 🙁')}</div>
  )

  if (!event.data?.id) return (
    <Loader className="mt-7 flex flex-col items-center" />
  )

  const meetURL = `${REACT_APP_MEET_URL}/${event.data?.id}/${event.data?.token}`

  return (
    <div className="m-5">
      <div className="block bg-white flex items-center justify-between shadow rounded p-5">
        <dl>
          <dt className="font-bold">{t('date')}</dt>
          <dd className="mb-3 text-first-up">{formatDate(event.data?.datetime, 'EEEE d MMMM HH:mm')}</dd>

          <dt className="font-bold">{t('status')}</dt>
          <dd className={cx("mb-3 text-first-up", {
            'text-green-600': event.data?.status === 'booked',
            'text-orange-600': event.data?.status === 'pending',
            'text-red-600': ['cancelled', 'noshow'].includes(event.data?.status),
          })}>{t(`status_${event.data?.status}`)}</dd>

          <dt className="font-bold">{t('service')}</dt>
          <dd className="mb-3">{event.data?.service?.label}</dd>

          <dt className="font-bold">{t('consumer')}</dt>
          <dd className="mb-3">{event.data?.consumer?.label}</dd>

          <dt className="font-bold">{t('resource')}</dt>
          <dd className="mb-3">{event.data?.resource?.label}</dd>
          {event.data?.remote ? (
            <>
              <dt className="font-bold">{t('link')}</dt>
              <dd>
                <a href={meetURL} className="text-blue-600" target="_blank" rel="noreferrer">{meetURL.replace('https://', '')}</a>
              </dd>
            </>
          ) : (
            <>
              <dt className="font-bold">{t('address')}</dt>
              <dd>
                {event.data?.location?.address}<br />
                {event.data?.location?.place_id ? (
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(event.data.location.address)}&destination_place_id=${event.data?.location?.place_id}`}
                    className="text-blue-600" target="_blank" rel="noreferrer">
                    {t('get_directions', 'Como llegar')}
                  </a>
                ) : event.data?.coords?.length === 2 && (
                  <a href={`https://www.google.com/maps/search/?api=1&query=${event.data.coords.join(",")}`} className="text-blue-600" target="_blank" rel="noreferrer">
                    Google Maps
                  </a>
                )}
              </dd>
            </>
          )}
        </dl>
      </div>
      {event.data?.status !== 'cancelled' && event.data?.day >= new Date().toLocaleDateString('fr-ca') && (
        <div>
          <button
            type="button"
            onClick={() => cancel.mutate()}
            className="inline-flex items-center px-4 py-2 mt-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:border-rose-700 active:bg-red-700 transition ease-in-out duration-150 cursor-not-allowed" disabled={cancel.isLoading}
          >
            {cancel.isLoading && (
              <Loader color="white" className="-ml-1 mr-3 flex flex-col items-center" />
            )} {t('cancel')}
          </button>
        </div>
      )}
    </div>
  )
}

export default Event;
