import { useState, useEffect } from 'react'

const options = {
  enableHighAccuracy: true,
  timeout: 8000,
  maximumAge: 0
}

const usePosition = () => {
  const [status, setStatus] = useState()
  const [coords, setCoords] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  const get = callback => {
    if (loading) return
    setLoading(true)
    setError()

    const success = ({ coords }) => {
      setLoading(false)

      const payload = [coords.latitude, coords.longitude].filter(Boolean).join(',')
      setCoords(payload)

      if (callback?.constructor === Function) callback(payload)
    };

    const error = (err) => {
      setLoading(false)

      if (err.code === window.GeolocationPositionError.PERMISSION_DENIED) {
        setError('position_error_denied')
      } else if (err.code === window.GeolocationPositionError.POSITION_UNAVAILABLE) {
        setError('position_error_unavailable')
      } else if (err.code === window.GeolocationPositionError.TIMEOUT) {
        setError('position_error_timeout')
      } else {
        setError('position_error')
      }

      if (callback?.constructor === Function) callback()
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  useEffect(() => {
    try {
      let result
      navigator.permissions.query({ name:'geolocation' }).then(result => {
        setStatus(result.state)
        result.addEventListener('change', setStatus(result.state));
      })
      return () => result && result.removeEventListener('change', () => setStatus(result.state))
    } catch {}
  }, [])

  useEffect(() => {
    if (status === 'granted') get()
    return () => setLoading(false)
  }, [status])

  return {
    get,
    coords,
    error,
    loading,
  }
}

export default usePosition
