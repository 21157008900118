import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from '@reach/router'
import { useQuery } from 'react-query'

import Loader from '../../Loader'
import useCMS from '../Wizard/useCMS'
import useTimer from '../../hooks/useTimer'

const {
  REACT_APP_API_URL,
} = process.env

export default function Payment(props) {
  const { t } = useTranslation()

  const secs = useTimer(20)
  const id = `events/${props.eventID}`.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isTest = !!params.get('test')
  const [loading, setLoading] = useState(false)
  const cms = useCMS()

  const event = useQuery(
    ['events', props.eventID, props.token],
    () => fetch(`${REACT_APP_API_URL}/${id}/${props.token}`).then(res => res.json()),
    { enabled: !!(props.eventID && props.token) }
  )

  const link = useQuery(
    ['events', props.eventID, 'payment', 'link', isTest.toString()],
    () => fetch(`${REACT_APP_API_URL}/${id}/payment/link?${params.toString()}`).then(res => res.json()),
    { enabled: !!(props.eventID && props.token) }
  )

  const data = event.data

  useEffect(() => {
    if (!props.status && secs <= 0 && link.data) {
      setLoading(true)
      window.location.href = link.data
    }
  }, [props.status, secs, link.data])

  if (!data?.id) return (
    <Loader className="h-screen flex flex-col items-center justify-center" />
  )

  return (
    <div className="md:mt-5">
      {props.status === 'error' ? (
        <>
          <div className="mb-5 bg-red-600 text-white p-5 md:rounded-lg">
            <div className="font-medium text-lg">
              {t('mp_payment_error_title', 'Hubo un error con el pago')}
            </div>
            <div className="text-red-200">
              {t('mp_payment_error_message', 'No se pudo procesar el pago correctamente')}
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href={link.data}
              rel="noopener noreferrer"
              target="_blank"
              className={`
                inline-block
                text-white/90 bg-primary/90 py-2 px-4 rounded-md
                hover:bg-primary hover:text-white
              `}
            >{t('try_again_now_and_pay', 'Intentar nuevamente')}</a>
          </div>
        </>
      ) : props.status === 'pendiente' ? (
        <>
          <div className="mb-5 bg-orange-600 text-white p-5 md:rounded-lg">
            <div className="font-medium text-lg">
              {t('mp_payment_pending_title', 'Tu pago quedo pendiente')}
            </div>
            <div className="text-orange-200">
              {t('mp_payment_pending_message', 'Te mantendremos informado')}
            </div>
          </div>
          <div className="flex justify-end">
            <Link
              to={`/turnos/${props.eventID}/${data.token}`}
              className={`
                inline-block
                text-white/90 bg-primary/90 py-2 px-4 rounded-md
                hover:bg-primary hover:text-white
              `}
            >{t('event_link_information', 'Ver datos del turno')}</Link>
          </div>
        </>
      ) : (
        <div className="md:rounded-lg bg-white p-5">
          <div className="flex flex-col space-y-5 mb-5">
            <div>{t('redirect_mp_message_1', 'Te vamos a redireccionar automaticamente a Mercado Pago para que puedas pagar por el turno y quede confirmado')}</div>
            <div>{t('cancelation_policies_message_1', 'En caso que canceles el turno se te devuelve el dinero automaticamente. Para ver los detalles de las politicas de devolucion podes entrar al link de abajo (se abre en un nueva pestaña)')}</div>
            <div><a href={cms?.links?.cancellation || ''} rel="noopener noreferrer" target="_blank" className="text-blue-600">{t('read_cancelation_policies', 'Leer politicas de cancelación y devolución')}</a></div>
          </div>
          <div className="flex space-x-4 items-center">
            <div>
              <a
                href={link.data}
                className={`
                  inline-block
                  text-white/90 bg-primary/90 py-2 px-4 rounded-md
                  hover:bg-primary hover:text-white
                `}
              >{t('redirect_now_and_pay', 'Pagar ahora')}</a>
            </div>
            {loading ? (
              <div className="text-sm text-gray-500">{t('redirecting_now', 'Redireccionando...')}</div>
            ) : (
              <div className="text-sm text-gray-500">{t('redirecting_to_mp_in_secs', 'Redireccionando en')} <strong>{secs}</strong> {t('seconds', 'segundos')}</div>
            )}
          </div>
        </div>
      )}

    </div>
  )
}
