import { useState, useEffect } from 'react'

import { Day } from './Day'

export function Days({ data, onClickDay, selected }) {
  const [days, setDays] = useState()

  useEffect(() => {
    let days = Object.entries(data?.ranges || {})
    if (days.length === 0) {
      days = Object.entries(data?.slots || {})
    }

    setDays([...days])
  }, [data])

  useEffect(() => {
    if (days?.[0] && selected === undefined) {
      onClickDay(...days[0])
    }

  }, [days, selected])

  if (!days) return null

  return days.map(([day, times]) => (
    <Day
      selected={selected === day}
      key={day}
      day={day}
      onClick={() => onClickDay(day, times)}
    />
  ))
}
