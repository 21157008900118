import { Router } from "@reach/router"

import Home from './Home'

const Teams = () => {
  return (
    <Router>
      <Home path="/" />
    </Router>
  )
}

export default Teams;
