import { useEffect } from 'react'
import { Router } from "@reach/router"

import Events from './Events/Events'
import EventsWizard from './Events/Wizard/Wizard'
import Teams from './Teams/Teams'
import Home from './Home/Home'
import { Resource } from './Resource/Resource'
import { MigrationResults } from './Migration/MigrationResults'

const {
  REACT_APP_ROOT_HOST
} = process.env

function App() {
  try {
    const hosts = REACT_APP_ROOT_HOST.split(',')
    if (!hosts.includes(window.location.origin)) {
      const [id] = window.location.hostname.split('.')
      window.location.href = `${hosts[0]}/equipos/${id}`
      return null
    }
  } catch {}

  return (
    <Router>
      <MigrationResults path="/especialidad/:specialty" />
      <Teams path="equipos/:teamID/*" />
      <Events path="turnos/*" />
      <Resource path="profesional/:slug/:id/*" />
      <Home path="/mx" />
      <Home path="/ec" />
      <Home path="/ar" />
      <Home path="/" />
      <EventsWizard path="*path" />
    </Router>
  );
}

export default App;
