import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'
import { useQuery } from 'react-query'

import logo from '../../assets/logo.svg'
import img1 from '../../assets/1.jpeg'
import img2 from '../../assets/2.jpeg'
import img3 from '../../assets/3.jpeg'
import img4 from '../../assets/4.jpeg'
import ios from '../../assets/Button---App-Store.png'
import android from '../../assets/Button---Google-Play-Store.png'
import bIos from '../../assets/big-Button---App-Store.png'
import bAndroid from '../../assets/big-Button---Google-Play-Store.png'
import phones from '../../assets/phones-img.png'
import bPhones from '../../assets/big-phones-img.png'
import installments from '../../assets/installments.png'
import daBox from '../../assets/daBox.png'
import inter from '../../assets/inter.png'
import msn from '../../assets/msn.png'
import wsp from '../../assets/wsp.png'
import fb from '../../assets/facebook.png'
import ig from '../../assets/instagram.png'
import lk from '../../assets/linkedin.png'
import wspB from '../../assets/wspB.png'
import fbB from '../../assets/facebookB.png'
import igB from '../../assets/instagramB.png'
import lkB from '../../assets/linkedinB.png'

import useCMS from '../../Events/Wizard/useCMS'

const {
  REACT_APP_HOME_ENDPOINT = "https://brand.wiri.la/home"
} = process.env

const Elements = ({subTitle, children}) => {
  return (
    <li>
      <h3 className="mb-[10px] text-[15px] font-medium">{subTitle}</h3>
      {children}
    </li>
  )
}

const NavElement = (props) => {
  const {
    mainTitle,
    children
  } = props 

  return (
    <div className="mb-[35px]">
      <h2 className="mb-[20px] text-[19px] font-medium text-primary">{mainTitle}</h2>
      <ul className="mb-[25px]">
        {children}
      </ul>
     </div>
  )
}

const data = [
  {
    mainTitle: 'Servicios populares',
    elements: [
      {
        subTitle: 'Chequeos médicos',
        subElements: [
          {
            title: 'Control general de rutina',
            link: ''
          },
          {
            title: 'Apto físico',
            link: ''
          },
          {
            title: 'Control de salud anual',
            link: ''
          }
        ]
      }
    ]
  }
]

// const Nav = () => {
//   return(
//     <NavElement mainTitle={mainTitle}>
//       {elements?.map(({subTitle, subElements}) => (
//         <Elements subTitle={subTitle}>
//           {subElements?.map(({title}) => {
//             return (
//               <li key={`navElementTitle-${title}`} className="mb-[10px] text-[15px]">{title}</li>
//             )
//           })}
//         </Elements>
//       ))}
//     </NavElement>
//   )
// }

const Buttons = ({onClick}) => {
  return(
    <div className="hidden lg:block">
      <button onClick={onClick} className="border rounded-full bg-primary h-[50px] w-[50px] text-[34px] text-white mr-4">
        {'<'}
      </button>

      <button onClick={onClick} className="border rounded-full bg-primary h-[50px] w-[50px] text-[34px] text-white">
        {'>'}
      </button>
    </div>
  )
}

export function Landing() {
  const [step, setStep] = useState(1)
  const [tab, setTab] = useState('on-site')
  // const [countItemsCard, setCountItemsCard] = useState(0)
  const [show, setShow] = useState(false)
  const cms = useCMS()
  const {t} = useTranslation()
  const content = useQuery('home:content', () => fetch(`${REACT_APP_HOME_ENDPOINT}/content.json`).then(res => res.json()))
  const containerSpecialtyCards = useRef(null)
  const containerCriticsCards = useRef(null)
  const countItemsCard = useRef(0)
  const countCriticsCard = useRef(0)
  const country = cms?.country?.id

  if (!country || !content.data) return null

  const cards1 = content.data[country]?.cards1 || content.data?.cards1
  const cards2 = content.data[country]?.cards2 || content.data?.cards2
  const cards3 = content.data[country]?.cards3 || content.data?.cards3

  const backToInit = ({target}) => {
    setTab(target.dataset.tab)
    countItemsCard.current = 0
    containerSpecialtyCards.current.setAttribute('style', 'margin-left: 0')
    containerSpecialtyCards.current.scrollLeft = 0
  }

  const showNav = () => setShow(prev => !prev)

  const moveTo = (direction, container, count) => {
    const elementWidth = container.current.firstChild.firstChild.clientWidth + 22
    direction === 'left'
      ? container.current.setAttribute('style', `margin-left: -${count.current * elementWidth}px`)
      : container.current.setAttribute('style', `margin-left: -${count.current * elementWidth}px`)
  }

  const animationSpecialtyCards = ({target}) => {
    const counter = countItemsCard.current
    const containerWidth = containerSpecialtyCards.current.firstChild.clientWidth
    const elementWidth = containerSpecialtyCards.current.firstChild.firstChild.clientWidth + 22
    if(target.innerText === '>') {
      if(containerWidth <= (countItemsCard.current * elementWidth) + 709) return
      countItemsCard.current = counter + 1
      moveTo('left', containerSpecialtyCards, countItemsCard)
      return
    }
    if(!(countItemsCard.current * elementWidth)) return
    countItemsCard.current = counter >= 0 ? counter - 1 : 0
    moveTo('right', containerSpecialtyCards, countItemsCard)
  }

  const animationCriticsCards = ({target}) => {
    const counter = countCriticsCard.current
    const containerWidth = containerCriticsCards.current.firstChild.clientWidth
    const elementWidth = containerCriticsCards.current.firstChild.firstChild.clientWidth + 22
    if(target.innerText === '>') {
      if(containerWidth <= (countCriticsCard.current * elementWidth) - elementWidth/2 ) return
      countCriticsCard.current = counter + 1
      moveTo('left', containerCriticsCards, countCriticsCard)
      return
    }
    if(!(countCriticsCard.current * elementWidth)) return
    countCriticsCard.current = counter >= 0 ? counter - 1 : 0
    moveTo('right', containerCriticsCards, countCriticsCard)
  }

  return (
    // ${show ? 'h-screen' : ''}
    <div className={`text-black overflow-hidden bg-bgGray`}>
      <div className="bg-white pb-9 lg:pb-20">
        <div className="shadow-md px-5 py-5 mb-9 lg:mb-[72px]">
          <div className="flex items-center justify-between lg:m-auto w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            <div className="-ml-5"><img src={logo} width="160" alt="logo de wiri salud" /></div>
            {/* <div onClick={showNav} className="sm:hidden">
              {show
                ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-10 h-10">
                  //   <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                  // </svg>
                )

                : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                )
              }
            </div> */}
            <div className="hidden lg:text-[22px] sm:flex sm:items-center sm:divide-x sm:divide-x-[3px] sm:divide-black overflow-hidden h-[25px]">
              <div className="cursor-pointer hover:text-primary duration-300 pr-7 md:pr-8 font-medium" data-toggle="categories">Servicios populares</div>
              <div className="cursor-pointer hover:text-primary duration-300 px-7 md:px-8 font-medium">
                <a href="https://ar.wiri.la/about" target="_blank" rel="noopener noreferrer">
                  Wiri Salud
                </a>
              </div>
              {/* <div className="cursor-pointer hover:text-primary duration-300 pl-7 md:pl-8 font-medium">Mi cuenta</div> */}
            </div>
          </div>
        </div>

        {/* {show && (
          <div className="bg-white w-full absolute top-[90px] z-50 p-[20px]">
            
          </div>
        )} */}

        <div className="flex justify-center items-center px-5 lg:px-0">
          <div className="w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            <div className="mb-3 lg:mb-6 2xl:mb-8">
              <div className="text-secondary text-[23px] sm:text-[30px] md:text-[36px] lg:text-[48px] xl:text-[58px] 2xl:text-[72px] font-medium mb-3"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_1', 'Bienvenido a la <span class="text-primary">revolución</span> de la salud', { context: country })
                }}
              />
              <div className="text-txtgray text-[15px] lg:text-[23.5px] xl:text-[28px] 2xl:text-[50px]"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_2', 'Turnos y estudios con profesionales de primer nivel para esta misma semana y a precios accesibles.', { context: country })
                }}
              />
            </div>

            <div className="bg-bgGray rounded-xl py-3 px-1 lg:py-[16px] 2xl:py-[28px] lg:px-4 max-w-[1388px]">
              <button className="flex justify-between items-center gap-x-3 w-full px-3" data-toggle="categories">
                <div className="text-txtgray text-[14px] lg:text-[20px]  xl:text-[24px] 2xl:text-[32px] sm:pr-6 truncate"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_3', 'Especialidad o nombre del profesional', { context: country })
                  }}
                />

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-primary h-6 w-6 lg:h-10 lg:w-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:justify-center items-center px-5 pt-[35px] pb-[50px] lg:pt-[85px] lg:pb-[75px]">
        <div className="w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="mb-3 lg:mb-6  w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            {/* md:mb-16 lg:mb-24 2xl:mb-[230px] */}
            <div className="md:flex md:items-center mb-[32px] lg:mb-8 2xl:mb-[150px] ">
              <div className="md:min-w-[450px] md:w-[450px] 2xl:min-w-[645px] 2xl:w-[645px] md:mr-11">
                <div className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px]  font-medium mb-2 lg:mb-4"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_4', '<span class="text-primary">Redefiniendo</span> la atención médica', { context: country })
                  }}
                />

                <div className="text-txtgray hidden lg:block text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-2"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'home_text_5',
                      'Recupera el control de tu salud ¡Nosotros te acompañamos! Agendá cualquiera de nuestros servicios y accedé a la mejor atención médica pagando solo cuando te atendés.',
                      { context: country }
                    )
                  }}
                />

                <button 
                  data-toggle="categories"
                  className="text-txtgray text-left lg:hidden text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-4 lg:mb-2"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_6', 'Agendá cualquiera de nuestros servicios y accedé a la mejor atención médica pagando solo cuando te atendés.', { context: country })
                  }}
                />

                <button
                  data-toggle="categories"
                  className="text-left hidden lg:block text-primary lg:text-[22px] 2xl:text-[28px] hover:underline"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_7', 'Buscar un turno en Wiri', { context: country })
                  }}
                />
              </div>

              <div>
                <div className="flex flex-col w-full lg:w-screen overflow-hidden">
                  <div
                    style={(window.screen.availWidth < 640) ? { width: window.screen.availWidth - 38, overflow: 'auto' } : {}}
                    className="flex items-center pb-[16px] lg:py-0 xl:py-3 mb-[10px] lg:mb-[14px] 2xl:mb-[32px]" data-tabs=""
                  >
                    <button
                      onClick={backToInit}
                      data-tab="on-site"
                      className="2xl:mr-[34px] lg:text-[19px] 2xl:text-[28px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none bg-indigo-100/70 text-primary"
                    >
                      Presencial
                    </button>

                    <button
                      onClick={backToInit}
                      data-tab="clinical-study"
                      className="2xl:mr-[34px] lg:text-[19px] 2xl:text-[28px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
                    >
                      Estudios
                    </button>

                    <button
                      onClick={backToInit}
                      data-tab="remote"
                      className="2xl:mr-[34px] lg:text-[19px] 2xl:text-[28px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
                    >
                      Telemedicina
                    </button>

                    <button
                      onClick={backToInit}
                      data-tab="check"
                      className="2xl:mr-[34px] lg:text-[19px] 2xl:text-[28px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
                    >
                      Chequeo
                    </button>
                  </div>

                  <div className="lg:mb-[48px]">
                    <div ref={containerSpecialtyCards} className="relative h-[316px] lg:h-[470px!important] xl:h-[550px!important] 2xl:h-[622px!important] overflow-x-auto overflow-y-hidden lg:overflow-visible">
                      <div className="grid items-center grid-flow-col auto-cols-max gap-x-5 absolute pr-[24px]">
                        {!!cards1.length && cards1.map((card, index) => {
                          return card.tab === tab && (
                            <Link
                              key={`cards1-${index}`}
                              to={`ar/${card.link}`}
                              data-tab={card.tab}
                              className={`bg-white w-[254px] min-w-[254px] lg:w-[340px!important] h-[300px] lg:h-[430px!important] xl:h-[520px!important] 2xl:h-[590px!important] xl:w-[380px!important] xl:min-w-[380px!important] 2xl:w-[504px!important] 2xl:min-w-[504px!important] lg:min-w-[340px!important] border rounded-2xl border-txtgray/30 ${card.link ? 'cursor-pointer' : ''}`}
                            >
                              <div
                                className="h-[129px] lg:h-[195px] xl:h-[235px] 2xl:h-[270px] bg-neutral-100 rounded-t-2xl overflow-hidden -mt-[1px] -ml-[1px] -mr-[1px]"
                                style={{ backgroundImage: `url(${card.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}
                              />
                              <div className="lg:pt-[8px] xl:pt-[20px] p-[14.76px] lg:px-3 overflow-hidden lg:mx-1">
                                <div className="text-secondary font-medium text-[15px] lg:text-[20px] xl:text-[22px] 2xl:text-[28px] lg:mb-[6px] xl:mb-[12px] truncate">{card.title}</div>
                                <div className="font-medium text-primary/90 lg:mb-[6px] xl:mb-[12px] text-[15px] lg:text-[20px] 2xl:text-[26px] truncate mb-1 xl-1">&nbsp;</div>
                                <div className="text-secondary/90 text-[12.5px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px]">{card.description}</div>
                              </div>

                              <button className="ml-3 text-txtgray rounded-full px-[20px] py-[6.75px] lg:p-5 text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] border border-2 border-color-txtgray hover:text-white hover:bg-primary">
                                Reservar ahora
                              </button>
                            </Link>
                          )
                        })}

                        <div className="flex flex-col items-center pr-4">
                          <Link
                            className="mb-4 text-white bg-primary text-center block text-[29px] w-[40px] h-[40px] rounded-full"
                            to="/ar/turnos/"
                          >
                            {'>'}
                          </Link>

                          <p className="text-[15px] lg:text-[22px] 2xl:text-[32px]  text-secondary font-medium">Ver otros</p>
                        </div>
                      </div>
                    </div>

                    <Buttons onClick={animationSpecialtyCards} />
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex md:items-center">
              <div className="md:min-w-[450px] md:w-[450px] 2xl:min-w-[645px] 2xl:w-[645px] md:mr-11">
                <div
                  className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px]  font-medium mb-2 lg:mb-4"
                  dangerouslySetInnerHTML={{ __html: t('home_text_8', 'Lo que dicen nuestros <span class="text-primary">Wirilovers</span>', { context: country })}}
                />
                <div
                  className="text-txtgray block text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-3"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_9', 'Nuestra misión es cambiar la forma en la que millones de personas cuidan su salud ¡Y lo estamos logrando!', { context: country })
                  }}
                />
                <button
                  data-toggle="categories"
                  className="text-left hidden lg:block text-primary lg:text-[22px] 2xl:text-[28px]  hover:underline"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_10', 'Ver cartilla de medicos', { context: country })
                  }}
                />
              </div>
              <div>
                <div className="flex flex-col w-full lg:w-screen overflow-hidden">
                  <div>
                    <div
                      ref={containerCriticsCards}
                      className="relative lg:w-[46vw] xl:w-[53vw] 2xl:w-[56vw] lg:mb-0 h-[300px] lg:h-[370px!important] xl:h-[450px!important] w-full 2xl:h-[550px!important] overflow-x-auto overflow-y-hidden lg:overflow-visible"
                    >
                      <div className=" grid grid-flow-col auto-cols-max gap-x-[15px] md:gap-x-5 pr-7">
                        {cards2.map((card, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-between bg-white w-[304px] min-w-[304px] lg:w-[340px!important] xl:w-[420px!important] h-[285px] lg:h-[340px!important] xl:h-[420px!important] 2xl:h-[520px!important] xl:min-w-[410px!important] lg:min-w-[340px!important] 2xl:w-[504px!important] 2xl:min-w-[504px!important] border rounded-2xl border-txtgray/30 block p-[15px] lg:pl-4 lg:pr-3 lg:py-6"
                          >
                            <div className=''>
                              <div className="text-txtgray text-[15px] lg:text-[19px] xl:text-[22px] 2xl:text-[28px] font-medium">{card.consumer}</div>
                              <div className="my-0 xl:my-3">
                                <div className="flex items-center m-[1px] lg:mt-1 lg:mb-2">
                                  <svg className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] 2xl:h-[32px] text-yellow-400 -ml-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] 2xl:h-[32px] text-yellow-400 -ml-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] 2xl:h-[32px] text-yellow-400 -ml-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] 2xl:h-[32px] text-yellow-400 -ml-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] 2xl:h-[32px] text-yellow-400 -ml-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                </div>
                              </div>
                              <div className="text-txtgray text-[15px] lg:text-[15.8px] xl:text-[19.8px] 2xl:text-[24px] mb-[35px]">{card.description}</div>
                            </div>

                            <div>
                              <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] 2xl:text-[21px] text-txtgray">
                                <span className="lg:text-[12px] xl:text-[15px] 2xl:text-[22px] align-middle leading-none mr-1">🩺</span>
                                Se atendió con
                                <span className="text-primary font-medium">{card.resource}</span>
                              </div>

                              <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] 2xl:text-[21px] text-txtgray my-1 lg:my-3 xl:my-5">
                                <span className="lg:text-[12px] xl:text-[15px] 2xl:text-[22px] align-middle leading-none mr-1">📅</span>
                                Reservó con solo
                                <span className="text-primary">{card.anticipation}</span>
                              </div>

                              <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] 2xl:text-[21px] text-txtgray">
                                <span className="lg:text-[12px] xl:text-[15px] 2xl:text-[22px] align-middle leading-none mr-1">🙌</span>
                                <span dangerouslySetInnerHTML={{ __html: card.line3 }}></span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Buttons onClick={animationCriticsCards} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(!!cards3?.length) && (
        <div className="bg-white flex md:justify-center items-center px-5 pb-[50px] pt-[35px] md:pb-0 md:pt-[150px]  md:py-24">
          <div className="lg:mb-6  w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            <div
              className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px]  font-medium mb-2 lg:mb-4 xl:mb-[40px]"
              dangerouslySetInnerHTML={{
                __html: t('home_text_11', 'Los <span class="text-primary">beneficios</span> de una prepaga, sin cuota mensual</div>', { context: country })
              }}
            />
            <div className="flex flex-wrap justify-between sm:justify-start">
              {cards3.map((card, index) => (
                <Link
                  key={`prepaid-${index}`}
                  className="mb-[20px] h-[56px] lg:h-[120px!important] border rounded-lg border-txtgray/30 flex items-center gap-x-[5px] lg:gap-x-3 px-[8px] lg:px-4 xl:py-[20px] w-[48%] sm:w-full sm:mr-[20px] max-w-[200px] lg:max-w-[300px] xl:max-w-[352px]"
                  to={`${cms.country.slug}/${card.link || 'turnos'}`}
                >
                  <div className="w-[20px] h-[20px] lg:w-[60px] lg:h-[60px] lg:text-[45px]">{card.icon}</div>
                  <div className="text-secondary font-medium leading-[16px] lg:leading-[36px] text-[15px] lg:text-[20px] xl:text-[26px] 2xl:text-[28px]">{card.title}</div>
                </Link>
              ))}
            </div>

            <Link
              to={'/ar/turnos/'}
              className="block text-[14px] lg:text-[20px] text-secondary text-center lg:hidden"
            >
              Ver todos los servicios
            </Link>
          </div>
        </div>
      )}

      <div className="hidden bg-white lg:flex md:justify-center items-center px-5 py-9 lg:py-24">
        <div className="mb-3 lg:mb-6 w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="flex flex-row justify-between lg:justify-start w-full px:16px">
            <div
              className="md:hidden text-[19px] font-medium mb-7"
              dangerouslySetInnerHTML={{
                __html: t('home_text_12', 'Simple, conveniente y <span class="text-primary">confiable</span>', { context: country })
              }}
            />

            <div className="lg:w-[400px] lg:min-w-[513px] xl:w-[500px] xl:min-w-[513px] 2xl:w-[650px] 2xl:min-w-[650px] lg:mr-[30px] xl:mr-[50px]">
              {step === 1 ? (
                <img className="" src={img1} alt="" />
              ) : step === 2 ? (
                <img className="" src={img2} alt="" />
              ) : step === 3 && (
                <img className="" src={img3} alt="" />
              )}
            </div>

            <div className="hidden md:flex md:flex-col md:w-[513px] md:min-w-[513px] 2xl:w-[927px] 2xl:min-w-[927px]">
              <div
                className="lg:text-[28px] xl:text-[32px] 2xl:text-[56px] font-medium mb-2 lg:mb-4"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_13', 'Simple, conveniente y <span class="text-primary">confiable</span>', { context: country })
                }}
              />

              <div>
                <button className="text-left flex flex-col mb-8" onClick={() => setStep(1)}>
                  <div className="flex">
                    <div
                      className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] 2xl:w-[79px] 2xl:min-w-[79px]
                        2xl:h-[79px] mr-5 lg:text-[19px] 2xl:text-[36px] font-medium border rounded-full
                        border-secondary/70 text-center pt-[3px] 2xl:pt-3 ${step === 1 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      1
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px] 2xl:text-[36px] 2xl:leading-[75px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_14', 'Encontrá el servicio que necesites', { context: country })}}
                    />
                  </div>

                  {step === 1 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7 2xl:leading-[42px] 2xl:text-[28px]"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_15', 'En nuestra cartilla podrás encontrar centros, médicos y profesionales matriculados de más de 40 especialidades.', { context: country })
                      }}
                    />
                  )}
                </button>

                <button className="text-left flex flex-col mb-8" onClick={() => setStep(2)}>
                  <div className="flex">
                    <div className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] 2xl:w-[79px] 2xl:min-w-[79px]
                      2xl:h-[79px] mr-5 lg:text-[19px] 2xl:text-[36px] font-medium border rounded-full
                      border-secondary/70 text-center pt-[3px] 2xl:pt-3 ${step === 2 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      2
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px] 2xl:text-[36px] 2xl:leading-[75px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_16', 'Seleccioná al profesional', { context: country })}}
                    />
                  </div>

                  {step === 2 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7 2xl:leading-[42px] 2xl:text-[28px]"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_17', 'Elegí al profesional que más te convenga según su ubicación, horarios, precios y recomendaciones de otros pacientes.', { context: country })
                      }}
                    />
                  )}
                </button>

                <button className="text-left flex flex-col mb-8" onClick={() => setStep(3)}>
                  <div className="flex">
                    <div className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] 2xl:w-[79px] 2xl:min-w-[79px]
                      2xl:h-[79px] mr-5 lg:text-[19px] 2xl:text-[36px] font-medium border rounded-full
                      border-secondary/70 text-center pt-[3px] 2xl:pt-3 ${step === 3 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      3
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px] 2xl:text-[36px] 2xl:leading-[75px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_18', 'Reservá tu turno y pagá solo por lo que usás', { context: country })}}
                    />
                  </div>

                  {step === 3 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7 2xl:leading-[42px] 2xl:text-[28px]"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_19', 'Reservá y atendete pagando solo por lo que usás, sin trámites, letras chicas ni cuotas mensuales.', { context: country })
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Banner */}
      <div className="bg-primary">
        <div className="flex flex-col md:flex-row md:justify-center items-center lg:justify-between py-[35px] pb-0 lg:pt-[40px] w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px]  m-auto">
          <div className="xl:w-[420px] xl:min-w-[420px] 2xl:w-[645px] 2xl:min-w-[645px] flex flex-col lg:flex-row md:px-0 px-[19px] sm:px-0">
            <div>
              <div className="text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px]  font-medium mb-2 lg:mb-4 text-white">Wiri es aún mejor desde la App</div>

              <div className="text-[15px] lg:text-[22px] 2xl:text-[32px]  text-white mb-[20px] 2xl:mb-[40px]">
                Descarga la app de Wiri y acceder a una experiencia integral del cuidado de tu salud.
              </div>

              <div className="flex justify-between xl:justify-start mb-[15px] mx-2 lg:mx-0 sm:mb-0">
                <a
                  target="_blank" rel="noopener noreferrer" href="https://onelink.to/p2xgje"
                >
                  <img className="md:hidden h-full" src={android} alt="Play Store" />
                  <img className="hidden md:block" src={bAndroid} alt="Play Store" />
                </a>

                <a
                  target="_blank" rel="noopener noreferrer" href="https://onelink.to/p2xgje"
                >
                  <img className="md:hidden h-full" src={ios} alt="App Store" />
                  <img className="hidden md:block" src={bIos} alt="App Store" />
                </a>
              </div>
            </div>
          </div>

          <div>
            <img className="lg:hidden" src={phones} alt="telefonos con ejemplo de las apps instaladas" />
            <img className="hidden lg:block 2xl:w-[1090px]" src={bPhones} alt="telefonos con ejemplo de las apps instaladas" />
          </div>
        </div>
      </div>

      <div className="bg-white flex md:justify-center items-center px-5 py-9 lg:pb-[150px] lg:pt-[80px] xl:pt-[120px]">
        <div className="flex md:justify-between mb-3 lg:mb-6 xl:mb-0 w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="hidden lg:block xl:w-[425px] 2xl:w-[691px] overflow-hidden rounded-xl mr-9">
            <img className="w-full" src={img4} alt="doctora sonriente con estetoscopio y lapiz en mano" />
          </div>

          <div className="flex flex-col lg:justify-center lg:w-[620px] lg:min-w-[620px] 2xl:w-[929px] 2xl:min-w-[929px] lg:mr-[0px]">
            <div
              className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px] 2xl:leading-[70px] font-medium mb-2 lg:mb-4 2xl:mb-[20px]"
              dangerouslySetInnerHTML={{ __html: t('home_text_20', 'Convirtiendo la atención médica en un derecho <span class="text-primary">universal</span>', { context: country })}}
            />

            <div
              className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px] mb-5 2xl:mb-[40px]"
              dangerouslySetInnerHTML={{
                __html: t('home_text_21', 'Wiri es una empresa de tecnología con una misión: que todos podamos disfrutar de una vida saludable sin importar de dónde venimos.', { context: country })
              }}
            />

            <div
              className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px] mb-8 2xl:mb-[60px]"
              dangerouslySetInnerHTML={{
                __html: t('home_text_22', 'Por eso creamos una plataforma donde podés acceder a servicios médicos de primer nivel accesibles, transparentes y convenientes. Juntos, estamos construyendo un sistema de salud sustentable donde todos hacemos parte. ¿Y vos, te unís a la revolución?', { context: country })
              }}
            />

            <button
              className="bg-primary lg:text-[24px] 2xl:text-[32px] rounded-full w-[145px] sm:w-[310px] lg:w-[430px] 2xl:w-[494px] px-2 py-2 lg:px-6 lg:py-6 text-white font-medium"
              dangerouslySetInnerHTML={{ __html: t('home_text_23', 'Ver más', { context: country })}}
            />
          </div>
        </div>
      </div>

      <div className="flex md:justify-center items-center px-5 pt-[35px] pb-[50px] lg:pt-[129px] lg:pb-[150px]">
        <div className="mb-3 lg:mb-0 w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div
            className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px] font-medium mb-[20px] sm:mb-4 lg:mb-8  xl:mb-[69px]"
            dangerouslySetInnerHTML={{ __html: t('home_text_24', 'Una <span class="text-primary">solución</span> que se adapta a todos', { context: country })}}
          />

          <div className="flex flex-col lg:flex-row justify-around">
            <div className="w-full mb-[50px] lg:mb-0 lg:w-[31.5%]">
              <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                <img className="w-full" src={installments} alt="imagen de wiri sin cuotas" />
              </div>
              <div>
                <div
                  className="text-[15px] lg:text-[24px] 2xl:text-[36px] font-medium mb-[9px] sm:mb-4"
                  dangerouslySetInnerHTML={{ __html: t('home_text_25', 'Wiri sin cuotas', { context: country })}}
                />

                <div
                  className="text-[15px] lg:text-[22px] 2xl:text-[30px] text-primary font-medium mb-[9px] sm:mb-4"
                  dangerouslySetInnerHTML={{ __html: t('home_text_26', '$0/mes', { context: country })}}
                />

                <div
                  className="text-[15px] text-txtgray lg:text-[21px] 2xl:text-[29px] mb-5 xl:mb-[40px]"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'home_text_27',
                      'La mejor atención médica para vos y tu familia sin trámites ni cuotas. Disfrutá de turnos y estudios médicos accesibles pagando solo por lo que usás.',
                      { context: country })
                    }}
                  />

                <button
                  data-toggle="categories"
                  className="bg-primary text-[15px] lg:text-[24px] 2xl:text-[32px] rounded-full w-[145px] lg:w-[250px] 2xl:w-[320px] px-2 py-[9px] lg:px-6 lg:py-4 2xl:py-[21px] text-center text-white font-medium block"
                  dangerouslySetInnerHTML={{ __html: t('home_text_28', 'Reservar turno', { context: country })}}
                />
              </div>
            </div>

            {country === 'ar' && (
              <div className="w-full mb-[50px] lg:mb-0 lg:w-[31.5%]">
                <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                  <img className="w-full" src={daBox} alt="imagen de la caja promo" />
                </div>
                <div>
                  <div className="text-[15px] lg:text-[24px] 2xl:text-[36px] font-medium mb-[9px] sm:mb-4">Wiri Plus x La Caja</div>

                  <div className="text-[15px] lg:text-[22px] 2xl:text-[30px]  text-primary font-medium mb-[9px] sm:mb-4">$3500/mes</div>

                  <div className="text-[15px] text-txtgray lg:text-[21px] 2xl:text-[29px] mb-5 xl:mb-[40px]">
                    Accedé a una cobertura médica desarrollada por Wiri en alianza con La Caja para cubrirte frente a gastos médicos inesperados. ¡Por solo $3500 por mes!
                  </div>

                  <a
                    href="http://wiri.abroker.com.ar/"
                    rel="noreferrer"
                    target="_blank"
                    className="bg-primary text-[15px] lg:text-[24px] 2xl:text-[32px] rounded-full w-[145px] lg:w-[250px] 2xl:w-[320px] px-2 py-[9px] lg:px-6 lg:py-4 2xl:py-[21px] text-center text-white font-medium block"
                  >
                      Ver más
                  </a>
                </div>
              </div>
            )}

            <div className="w-full lg:w-[31.5%]">
              <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                <img className="w-full" src={inter} alt="imagen de wiri para empresas" />
              </div>
              <div>
                <div className="text-[15px] lg:text-[24px] 2xl:text-[36px] font-medium mb-[9px] sm:mb-4">Wiri para empresas</div>

                <div className="text-[15px] lg:text-[22px] 2xl:text-[30px]  text-primary font-medium mb-[9px] sm:mb-4">
                  Cotizá haciendo click <a href="https://empresas.wiri.la/" target="_blank" rel="noopener noreferrer" className="hover:underline">acá</a>
                </div>

                <div className="text-[15px] text-txtgray lg:text-[21px] 2xl:text-[29px] mb-5 xl:mb-[40px]">
                  Sabemos que la salud de tus empleados es la inversión más rentable. Cuidá de tu equipo con nuestros programas especiales para empresas
                </div>

                <a
                  target="_blank"
                  href="https://empresas.wiri.la/"
                  rel="noreferrer"
                  className="bg-primary text-[15px] lg:text-[24px] 2xl:text-[32px] rounded-full w-[145px] lg:w-[250px] 2xl:w-[320px] px-2 py-[9px] lg:px-6 lg:py-4 2xl:py-[21px] text-center text-white font-medium block"
                >
                  Ver más
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white lg:flex md:justify-center items-center pb-[50px] lg:py-0">
        <div className="bg-primary px-[20px] py-[35px] lg:p-24 2xl:py-[130px] 2xl:pl-[148px] 2xl:pr-[107px]">
          <div
            className="text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px]  font-medium mb-4 lg:mb-8 text-white"
            dangerouslySetInnerHTML={{ __html: t('home_text_29', '¿No necesitás ningún turno?', { context: country })}}
          />

          <div
            className="text-white text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-4 lg:mb-9 2xl:mb-[61px]"
            dangerouslySetInnerHTML={{
              __html: t('home_text_30', 'Dejanos tu mail y recibí descuentos, ofertas exclusivas, información sobre salud y mucho más.', { context: country })
            }}
          />

          <div className="flex items-center bg-white h-[42px] lg:h-[71px] 2xl:h-[90px] pl-4 rounded-lg">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] h-[18px] sm:w-[20px] sm:h-[20px] 2xl:w-[44px] 2xl:h-[33px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
              </svg>
            </div>
            <input className="grow text-[13px] sm:text-[22px] 2xl:text-[32px] focus:outline-0 focus:shadow-transparent border-none" placeholder={t('home_text_31', 'email@email.com', { context: country })} type="email" name="email" id="email" />

            <button className="bg-primary h-full w-[50px] lg:w-[78px] 2xl:w-[100px]  rounded-r-lg border border-l-0 border-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] h-[18px] sm:w-[20px] sm:h-[20px] 2xl:w-[40px] 2xl:h-[40px] text-white m-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        </div>

        <div className="px-[20px] pt-[35px] lg:p-20 2xl:py-[51px] 2xl:pl-[107px] 2xl:pr-[148px]">
          <div
            className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] 2xl:text-[56px] font-medium mb-4 lg:mb-2"
            dangerouslySetInnerHTML={{ __html: t('home_text_32', '¿O querés <span class="text-primary">más información</span>?', { context: country })}}
          />

          <div
            className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px] mb-[21px] lg:mb-9"
            dangerouslySetInnerHTML={{
              __html: t(
                'home_text_33',
                'Contactanos a través de nuestros <br class="hidden 2xl:block" /> canales oficiales para que uno de nuestros Wiriers pueda darte una mano.',
                { context: country }
              )
            }}
          />

          <div>
            <div className="flex items-center mb-[26px]">
              <div className="mr-3">
                <img className="2xl:w-[46px]" src={wsp} alt="logo de whatsapp" />
              </div>

              <a
                href={t('home_text_whatsapp_link', 'https://wa.me/5491168167431', { context: country })}
                className="text-[15px] lg:text-[21px] 2xl:text-[32px]"
                rel="noopener noreferrer">{t('home_text_whatsapp_label', '+ 54 9 11 6816 7431', { context: country })}
              </a>
            </div>

            <div className="flex items-center">
              <div className="mr-3">
                <img className="2xl:w-[46px]" src={msn} alt="logo de msn" />
              </div>

              <a
                href="https://facebook.com/WiriSalud"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[15px] lg:text-[21px] 2xl:text-[32px]"
              >
                /WiriSalud
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:justify-center items-center px-5 py-[35px] pb-[50px] lg:py-[80px] xl:py-[105px]">
        <div className="w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="sm:grid sm:grid-cols-3 sm:grid-flow-row">
            <div className="sm:mr-6 sm:mb-0 mb-[50px] md:mr-12">
              <Link to="/" className="mb-[20px] lg:mb-7 xl:mb-9 block">
                <img src={logo} className="w-[160px] min-w-[160px] xl:w-[225px] xl:h-[92px] ml-[-26px]" alt="Wiri Logo" />
              </Link>

              <div
                className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[28px]  mb-5 lg:mb-7 xl:mb-9 leading-7 xl:leading-[36px]"
                dangerouslySetInnerHTML={{ __html: t('home_text_34', 'La salud que merecés al <br className="sm:hidden" /> precio que necesitás', { context: country })}}
              />

              <div className="flex items-center">
                <div className="w-[20px] h-[20px] 2xl:w-[17px] 2xl:h-[37px] mr-[25px] lg:mr-[18px] xl:mr-[40px]">
                  <a href="https://www.facebook.com/WiriSalud/" target="_blank" rel="noopener noreferrer">
                    <img className="2xl:hidden" src={fb} alt="logo de facebook" />
                    <img className="hidden 2xl:block" src={fbB} alt="logo de facebook" />
                  </a>
                </div>
                <div className="w-[21px] h-[20px] 2xl:w-[37px] 2xl:h-[37px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a href="https://instagram.com/wirisalud" target="_blank" rel="noopener noreferrer">
                    <img className="h-[20px] 2xl:hidden" src={ig} alt="logo de instagram" />
                    <img className="hidden 2xl:block" src={igB} alt="logo de instagram" />
                  </a>
                </div>
                <div className="w-[20px] h-[20px] 2xl:w-[37px] 2xl:h-[37px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a href="https://www.linkedin.com/company/wirisalud/" target="_blank" rel="noopener noreferrer">
                    <img className="2xl:hidden" src={lk} alt="logo de linkedin" />
                    <img className="hidden 2xl:block"  src={lkB} alt="logo de linkedin" />
                  </a>
                </div>
                <div className="w-[20px] h-[20px] 2xl:w-[37px] 2xl:h-[37px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a
                    href="https://api.whatsapp.com/send?phone=5491168167431&amp;text=%C2%A1Hola!%20%F0%9F%91%8B%20Quiero%20contactar%20con%20Wiri%20"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="2xl:hidden" src={wsp} alt="logo de whatsapp" />
                    <img className="hidden 2xl:block"  src={wspB} alt="logo de whatsapp" />
                  </a>
                </div>
              </div>
            </div>

            <div className="sm:mb-0 mb-[50px]">
              <div
                className="text-txtgray font-medium text-[15px] lg:text-[22px] 2xl:text-[32px] mb-[10px] lg:mb-7 xl:mb-9"
                dangerouslySetInnerHTML={{ __html: t('home_text_35', '¿Querés ser prestador?', { context: country })}}
              />

              <div
                className="text-txtgray text-[15px] lg:text-[20px] 2xl:text-[28px] mb-5 lg:mb-7 xl:mb-10 lg:leading-[32px] xl:leading-[42px]"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'home_text_36',
                    'La red de Wiri está en constante crecimiento, sumando nuevos prestadores que quieren seguir construyendo este sistema de salud sustentable.',
                    { context: country }                    
                    )
                  }}
                />

              <a
                href="https://prestadores.wiri.la/tarifas"
                target="_blank"
                rel="noopener noreferrer"
                className="text-txtgray w-[145px] text-[15px] lg:text-[20px] 2xl:text-[28px] rounded-full lg:w-[180px] xl:w-[210px] 2xl:w-[244px] px-4 py-2 lg:px-6 lg:py-3 text-center font-medium block border border-secondary hover:bg-primary hover:text-white hover:border-primary duration-500"
                dangerouslySetInnerHTML={{ __html: t('home_text_37', 'Ver más', { context: country })}}
              />
            </div>

            <div className="sm:ml-6 md:ml-20">
              <div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a data-toggle="categories">Reservar un turno</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://ar.wiri.la/about">Sobre nosotros</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://ar.wiri.la/wiriplus">Wiri Plus</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://prestadores.wiri.la/tarifas">Quiero ser prestador</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://comunidad.wiri.la/">Blog</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://ar.wiri.la/ayuda">Preguntas frecuentes</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://legal.wiri.la">Términos y condiciones</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] 2xl:text-[32px]  lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5491168167431&amp;text=%C2%A1Hola!%20Quiero%20contactar%20con%20Wiri%20%F0%9F%99%8C">Ayuda</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white pt-[15px] pb-[22px] px-[15px] lg:p-7 2xl:pt-[50px] 2xl:pb-[33px] text-center text-txtgray text-[13px] lg:text-[22px] 2xl:text-[28px]"
        dangerouslySetInnerHTML={{ __html: t('home_text_38', 'Wiri Salud - Todos los derechos reservados © 2022', { context: country })}}
      />
    </div>
  )
}
