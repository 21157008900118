import { useTranslation } from 'react-i18next'

export function Header({ resource }) {
  const { t } = useTranslation()

  const specialties = Object.values(
    resource.services?.reduce((hash, item) => {
      const id = item.id.split('/')?.[0]
      const label = item.label.split('/')?.[0]?.trim()
      if (!id) return hash
      return { ...hash, [id]: { id, label } }
    }, {})
  )

  return (
    <div
      id="header"
      className="flex bg-white p-4 sm:rounded-lg gap-x-3 sm:gap-x-4 lg:gap-x-6 xl:gap-x-8"
    >
      <div className="flex flex-col justify-end items-center">
        <div
          className="bg-gray-200"
          style={{
            borderRadius: '20px',
            backgroundImage: resource.image ? `url(${resource.image})` : undefined,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: 104,
            height: 116,
          }}
        />
      </div>
      <div className="grow flex flex-col justify-center">
        <div className="flex items-center justify-between">
          <div className="text-secondary font-medium grow">{resource.label}</div>
        </div>
        <div className="text-[13px] opacity-60 font-medium mb-2 capitalize">
          {specialties.map(specialty => t(specialty.id, specialty.label)).filter(Boolean).join(' | ')}
        </div>
        {resource.score >= 0 && (
          <a href="#reviews" className="flex items-center gap-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="text-[#FDCD00] h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <div className="text-sm font-medium">{Number(resource.score).toLocaleString()}</div>
            {resource.reviews > 0 && (
              <div className="text-sm font-medium">({t('review', `${resource.reviews} reseñas`, { count: resource.reviews })})</div>
            )}
          </a>
        )}
      </div>
    </div>
  )
}
