import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'
import { add } from 'date-fns'

import api from '../../services/api'
import Loader from '../../Loader'
import { Picker } from '../../Picker/Picker'
import { Days } from './Days'
import { Slot } from './Slot'

const getResourseAvaiability = ({ options, date }) => ({ pageParam }) => {
  const params = { ...options, startsAt: pageParam || date.getTime() }
  params.endsAt = add(params.startsAt, { days: 3 }).getTime()
  return api('/availability', params)
}

export function Availability(props) {
  const { t } = useTranslation()
  const { ref, inView } = useInView();
  const [date, setDate] = useState(new Date())
  const [day, setDay] = useState()
  const [times, setTimes] = useState()

  const { options } = props

  const query = useInfiniteQuery(
    ['availability/query', { ...options, startsAt: date.getTime() }],
    getResourseAvaiability({options, date}),
    {
      enabled: !!inView && !!options.service && !!options.financier && !!options.location && !!options.team,
      refetchInterval: 60_000,
      refetchOnWindowFocus: "always",
      onError: () => {},
      getNextPageParam: (lastPage, allPages) => {
        let days = Object.keys(lastPage?.ranges || {})
        let daysInMilliseconds = []
        if (days.length === 0) {
          days = Object.keys(lastPage?.slots || {})
          try {
            daysInMilliseconds = days.map(day => new Date(day).getTime()).sort((a, b) => b - a)
          } catch (e) {
            console.warn(e)
          }
        }
        if (days.length === 0 || daysInMilliseconds.length === 0) return undefined
        return add(daysInMilliseconds[0], { days: 1 }).getTime()
      }
    }
  );

  const onDayClick = date => {
    setDate(date)
  }

  const onClickDay = (day, times) => {
    setTimes(Object.entries(times))
    setDay(day)
  }

  if (query.isFetched && !Object.keys(query?.data?.pages?.[0]?.slots || {}).length) return (
    <div className="p-3 mt-3 text-lg text-center">
      {t('check_filters_warning', 'No encontrarmos turnos disponibiles para los filtros seleccionados.')}
    </div>
  )

  return (
    <div ref={ref} className="bg-white p-4 mt-4 sm:rounded-lg" id="availability">
      <div className="flex items-center justify-between gap-x-1">
        <div className="md:text-lg font-medium truncate">{t('select_date_and_time', 'Seleccionar fecha y horario')}</div>
        <div>
          <Picker
            date={date}
            short
            onDayClick={onDayClick}
          />
        </div>
      </div>
      <div className="-mx-4 mt-4">
        <div className="overflow-x-auto flex whitespace-nowrap flex-nowrap gap-x-2 px-4 pb-4">
          {query.data?.pages?.flatMap((page, index) => (
            <Days
              key={index}
              data={page}
              selected={day}
              onClickDay={onClickDay}
            />
          ))}
          {query.hasNextPage && (
            <button
              onClick={() => query.fetchNextPage()}
              disabled={query.isFetching}
              className={`
                flex flex-col items-center justify-center h-[71px] w-[65px] min-h-[71px] min-w-[65px] border rounded-lg
                text-secondary/60 hover:text-primary hover:border-primary/30 hover:bg-primary/10 active:bg-white
              `}
            >
              {query.isFetching ? (
                <Loader />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                </svg>
              )}
            </button>
          )}
        </div>
        {times && (
          <div className="overflow-x-auto flex whitespace-nowrap flex-nowrap gap-x-2 px-4 pb-4 -mb-4">
            {times.map(([time, entry]) => (
              <Slot
                key={time}
                {...options}
                {...entry}
                time={time}
                day={day}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
