import { useEffect, useState } from 'react'
import { Link, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import useCMS from './useCMS'
import useStore from './useStore'

const {
  REACT_APP_API_URL
} = process.env

const request = input => () => fetch(`${REACT_APP_API_URL}/resources?q=${input}`).then(res => res.json())

const Resources = () =>  {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const query = useQuery(
    ['resources', 'finder', input].filter(Boolean),
    request(input),
    { enabled: !!input && input.length >= 3 }
  )
  return (
    <div>
      <input
        type="text"
        onChange={e => setInput(e.target.value)}
        value={input || ''}
        className="w-full border-0 bg-slate-100 rounded-lg outline-blue-100 mb-6"
        placeholder={t('resources_finder_placeholder', 'Encontrá un profesional por su nombre')}
      />
      {Array.isArray(query.data) && query.data.length > 0 && (
        <div className="mb-10 border-t">
          {query.data.map(resource => (
            <div key={resource.id} className="mb-3 border-b p-3">
              <Link className="flex items-center gap-x-3" to={`/profesional/${resource.slug}/${resource.id.replace('resources/', '')}`}>
                <div
                  className="bg-gray-200"
                  style={{
                    borderRadius: '20px',
                    backgroundImage: resource.image ? `url(${resource.image})` : undefined,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: 40,
                    height: Math.round((40 * 116) / 104),
                  }}
                />
                <div className="py-0">
                  <div className="text-black">{resource.label}</div>
                  <div className="text-sm text-gray-400">{resource.specialties.map(s => t(s.id, s.label)).join(' | ')}</div>
                </div>

              </Link>
            </div>

          ))}
        </div>
      )}
    </div>
  )
}

const Categories = props => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const cms = useCMS()
  const [entries, setEntries] = useState([])
  const update = useStore(state => state.update)

  const onClick = entry => {
    update({ coords: undefined, address: undefined })
  }

  useEffect(() => {
    const entries = Object.values(cms?.categories?.current?.childrens || cms?.categories?.childrens || {})

    if (entries.length === 1 && cms?.zones?.current?.slug) {
      const url = [cms?.zones?.current?.slug, entries[0].slug].filter(Boolean).join('')
      navigate(url, { replace: true })
    } else {
      setEntries(entries)
    }
  }, [cms])

  if (entries.length <= 1 || !cms || !cms?.zones?.current?.slug) return null

  return (
    <div className={props.className}>
      <div className="flex items-center mb-6">
        <div
          className="text-[19px] font-medium leading-none"
          dangerouslySetInnerHTML={{
            __html: t(`category_finder_title`, `¿Qué <span class="text-primary">buscás</span>?`)
          }}
        />
      </div>

      {!!cms?.categories && !cms?.categories?.current && <Resources />}
      <div className="flex flex-col space-y-4">
        {entries.map(entry => (
          <Link
            onClick={() => onClick(entry)}
            to={`${cms.zones.current.slug}${entry.slug}`} key={entry.slug}
          >
            <div className="text-[15px] text-black">{entry.label}</div>
            {entry.subtitle && (
              <div className="leading-5">{entry.subtitle}</div>
            )}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Categories
