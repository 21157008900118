(() => {
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  function setDocHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  window.addEventListener("resize", setDocHeight);
  window.addEventListener("orientationchange", setDocHeight);

  setDocHeight();

  JSON.clone = (payload) => {
    try {
      return JSON.parse(JSON.stringify(payload));
    } catch {
      return payload;
    }
  };

  document.addEventListener('click', e => {
    try {
      const flag = Array.from(e.target.classList).includes('more-content')
      if (!flag) return
      e.target.parentElement.nextElementSibling.classList.toggle('hidden')
    } catch {}
  })
})();
