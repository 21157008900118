import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Link } from '@reach/router'
import { useTranslation } from "react-i18next";
import create from 'zustand'

import { formatDate } from '../i18n'

import encode from '../services/encode'
import api from '../services/api'
import Loader from '../Loader'
import Avatar from '../assets/avatar-md.svg'

const $params = new URLSearchParams(window.location.search)
let $date = new Date().toLocaleDateString('fr-ca')
try {
  const date = new Date($params.get('date') || $params.get('day') || $params.get('dia') || $params.get('fecha'))
  if (date.getTime()) {
    $date = date.toLocaleDateString('fr-ca')
  }
} catch {}

const useStore = create(set => ({
  date: $date,
  location: '',
  resource: '',
  service: '',
  financier: '',
  set: payload => set(state => ({ ...state, ...payload })),
}))

const Filters = (props) => {
  const store = useStore()

  const { t } = useTranslation()

  const onChange = ({ target: { name, value } }) => {
    store.set({ [name]: value })
  }

  return (
    <div className="sticky top-0 bg-gray-100 py-3">
      <div
        className="md:flex md:justify-between space-y-2 md:space-y-0 md:space-x-3 overflow-x-auto"
      >
          {props.team.services.length > 1 && (
            <div className="flex-none md:w-1/4 md:flex-1">
              <select
                value={store.service}
                name="service"
                onChange={onChange}
                className={`${!store.service ? 'bg-red-200 hover:bg-red-300 hover:bg-red-500 focus:border-red-700' : 'bg-white hover:bg-gray-50 hover:bg-gray-100 focus:border-indigo-300'} focus:outline-none active:outline-none min-w-0 max-w-none rounded-md w-full border-none shadow py-2 font-base text-sm text-black text-left`}>
                <option value="">{t(store.service ? 'all' : 'service', { context: 'filters' })}</option>
                {props.team.services.map(service =>
                  <option
                    disabled={
                      props.team.resources
                        .filter(resource => !store.resource || resource.id === store.resource)
                        .filter(resource => !store.financier || resource.financiers.some(f => f.key === store.financier))
                        .filter(resource => !store.location || resource.locations.includes(store.location))
                        .filter(resource => resource.services.some(s => s.key === service.key))
                        .length === 0
                    }
                    key={service.key}
                    value={service.key}
                  >{t(service.key, service.label)}</option>
                )}
              </select>
            </div>
          )}

          {props.team.financiers.length > 1 && (
            <div className="flex-none md:w-1/4 md:flex-1">
              <select
                value={store.financier}
                name="financier"
                onChange={onChange}
                className={`${!store.financier ? 'bg-red-200 hover:bg-red-300 hover:bg-red-500 focus:border-red-700' : 'bg-white hover:bg-gray-50 hover:bg-gray-100 focus:border-indigo-300'} focus:outline-none active:outline-none min-w-0 max-w-none rounded-md w-full border-none shadow py-2 font-base text-sm text-black text-left`}>
                <option value="">{t(store.financier ? 'all' : 'financier', { context: 'filters' })}</option>
                {props.team.financiers.map(financier =>
                  <option
                    disabled={
                      props.team.resources
                        .filter(resource => !store.resource || resource.id === store.resource)
                        .filter(resource => !store.service || resource.services.some(s => s.key === store.service))
                        .filter(resource => !store.location || resource.locations.includes(store.location))
                        .filter(resource => resource.financiers.some(f => f.key === financier.key))
                        .length === 0
                    }
                    key={financier.key} value={financier.key}>{t(financier.key, financier.label)}</option>
                )}
              </select>
            </div>
          )}

          {props.team.resources.length > 1 && (
            <div className="flex-none md:w-1/4 md:flex-1">
              <select
                value={store.resource}
                name="resource"
                onChange={onChange}
                className="min-w-0 max-w-none rounded-md w-full border-none shadow py-2 bg-white font-base text-sm text-black hover:bg-gray-100 focus:outline-none focus:border-indigo-300 text-left hover:bg-gray-50 focus:outline-none active:outline-none">
                <option value="">{t(store.resource ? 'all' : 'resource', { context: 'filters' })}</option>
                {props.team.resources.map(resource => (
                  <option disabled={
                    (store.service && !resource.services.some(service => service.key === store.service)) ||
                    (store.financier && !resource.financiers.some(financier => financier.key === store.financier)) ||
                    (store.location && !resource.locations.includes(store.location))
                  }
                  key={resource.id} value={resource.id}>{resource.label}</option>
                ))}
              </select>
            </div>
          )}

          {Object.keys(props.team.locations).length > 1 && (
            <div className="flex-none md:w-1/4 md:flex-1">
              <select
                value={store.location}
                name="location"
                onChange={onChange}
                className="min-w-0 max-w-none rounded-md w-full border-none shadow py-2 bg-white font-base text-sm text-black hover:bg-gray-100 focus:outline-none focus:border-indigo-300 text-left hover:bg-gray-50 focus:outline-none active:outline-none">
                <option value="">{t(store.location ? 'all' : 'location', { context: 'filters' })}</option>
                {Object.entries(props.team.locations).map(([locationID, location]) => (
                  <option
                    disabled={
                      props.team.resources
                        .filter(resource => !store.resource || resource.id === store.resource)
                        .filter(resource => !store.service || resource.services.some(s => s.key === store.service))
                        .filter(resource => !store.financier || resource.financiers.some(f => f.key === store.financier))
                        .filter(resource => resource.locations.includes(locationID))
                        .length === 0
                    }
                    key={locationID} value={locationID}>{location.address}</option>
                ))}
              </select>
            </div>
          )}

          <div className="flex-none md:w-1/4 md:flex-1">
            <input
              type="date"
              value={store.date}
              name="date"
              onChange={onChange}
              className="min-w-0 max-w-none whitespace-nowrap w-full rounded-md border-none shadow py-2 bg-white font-base text-sm text-black hover:bg-gray-100 focus:outline-none focus:border-indigo-300 text-left hover:bg-gray-50 focus:outline-none active:outline-none" />
          </div>
      </div>
      {(!store.service || !store.financier) && (
        <div className="text-sm text-yellow-700 mt-3 text-center bg-yellow-100 border border-yellow-500 rounded-md p-3">
          {t('select_service_financier_filter', 'Para tomar un turno seleccione un servicio y una cobertura de los selectores de arriba')}
        </div>
      )}
    </div>
  )
}

const Slot = props => {
  const code = encode(props.slot)
  return (
    <Link
      to={`/turnos/checkout/${code}`}
      className="
        text-sm bg-blue-100 p-2 rounded text-blue-600"
    >
      {props.slot.time}
    </Link>
  )
}

const Day = (props) => {
  return (
    <>
      <div>
        <div className="text-sm my-1 whitespace-nowrap text-first-up">
          {formatDate(new Date(`${props.day}T00:00`), 'EEEE dd MMMM')}
        </div>
        <div className="overflow-x-auto space-x-2 flex">
          {Object.keys(props.slots).sort().map(time => (
            <Slot
              key={time}
              slot={{
                teamID: props.teamID,
                locationID: props.locationID,
                resourceID: props.resourceID,
                serviceKey: props.service,
                financierKey: props.financier,
                day: props.day,
                time,
                source: 'cartilla'
              }}
            />
          ))}
        </div>
      </div>
    </>
  )
}

const Availability = (props) => {
  const { t } = useTranslation()
  const store = useStore()
  const [slots, setSlots] = useState({})
  const [date, setDate] = useState(store.date ||new Date().toLocaleDateString('fr-ca'))

  const key = ['availability', props.teamID, props.locationID, props.resourceID, store.service, store.financier, date].filter(Boolean)

  const availability = useQuery(
    key,
    () => api(`/${props.teamID}/availability`, {
      visibility: 'unlisted',
      team: props.teamID,
      location: props.locationID,
      resource: props.resourceID,
      service: store.service,
      financier: store.financier,
      startsAt: date,
      endsAt: new Date(date).getTime() + 3 * 24 * 60 * 60 * 1000,
      marketplace: 'cartilla',
      valid: 'cartilla',
      price: 'cartilla',
    }),
    {
      enabled: !!(props.teamID && props.resourceID && props.locationID && date && store.service && store.financier)
    }
  )

  const next = () => {
    let candidate = [
      new Date().toLocaleDateString('fr-ca'),
      date,
      ...Object.keys(slots || {}),
      new Date(store.date).toLocaleDateString('fr-ca')
    ].filter(Boolean).sort().reverse()[0]

    candidate = new Date(`${candidate}T00:00`).getTime() + 24 * 60 * 60 * 1000
    candidate = new Date(candidate).toLocaleDateString('fr-ca')
    if (candidate !== new Date(date).toLocaleDateString('fr-ca')) setDate(candidate)
  }

  useEffect(() => {
    setDate(store.date)
    setSlots({})
  }, [store.date])

  useEffect(() => {
    setSlots({})
  }, [props.locationID, props.resourceID, store.service, store.financier])

  useEffect(() => {
    setSlots(slots => ({ ...slots, ...availability.data?.slots }))
  }, [availability.data])

  if (!store.service || !store.financier) return (
    <div>
      <div className="space-y-5">
        <div className="text-sm text-yellow-700">
          {t('select_service_financier_filter', 'Para tomar un turno seleccione un servicio y una cobertura de los selectores de arriba')}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="overflow-hidden space-y-5">
        {availability.isLoading && (
          <div className="text-sm text-green-700">
            {t('loading_availability', 'Cargando disponibilidad')}
          </div>
        )}
        {slots && Object.keys(slots).sort().map(day =>
          <Day
            key={day}
            day={day}
            slots={slots[day]}
            teamID={props.teamID}
            locationID={props.locationID}
            resourceID={props.resourceID}
            service={store.service}
            financier={store.financier}
          />
        )}
      </div>
      <div className="text-right">
        <button className="mt-4 text-sm text-gray-500" onClick={next}>
          {t('more_days', 'Mas dias')}
        </button>
      </div>
    </div>
  )
}

const Profile = (props) => {
  const { t } = useTranslation()

  const resourceID = props.resource?.id || `resources/${props.resourceID}`.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim()

  const queryOptions = {
    enabled: !props.resource?.label && !!(props.resourceID || props.resource?.id),
    initialData: props.resource
  }

  const query = useQuery(resourceID,
    () => api(`/${resourceID}`),
    queryOptions
  )

  return (
    <div className="bg-white p-5 shadow-md rounded">
      <div className="flex items-center mb-3">
        <div className="rounded-full overflow-hidden" style={{ width: '60px', minWidth: '60px', maxWidth: '60px' }}>
          <img src={query.data.image || Avatar} className="w-full" alt="" />
        </div>
        <div className="ml-6 flex-1 w-full overflow-hidden">
          <div className="font-medium text-lg text-blue-600">{query.data.label}</div>
          {query.data.specialties?.length > 0 && (
            <div className="text-sm text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap">{query.data.specialties.map(specialty => t(specialty.key, specialty.label)).join(', ')}</div>
          )}
          {props.location?.address && (
            <div className="text-sm">{props.location.address}</div>
          )}
        </div>
      </div>

      {props.location?.address && (
        <Availability
          teamID={props.team.id}
          resourceID={resourceID}
          locationID={props.location.id}
        />
      )}
    </div>
  )
}

const Home = (props) => {
  const { t } = useTranslation()
  const store = useStore()

  const teamID = props.team?.id || `teams/${props.teamID}`.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim()
  const params = new URLSearchParams(window.location.search).toString()

  const queryOptions = {
    enabled: !props.team?.label && !!(props.teamID || props.team?.id),
    initialData: props.team
  }

  const query = useQuery(
    teamID,
    () => api(`/${teamID}${params ? `?${params}` : ''}`),
    queryOptions
  )

  useEffect(() => {
    const payload = {}

    if (query.data?.services?.length === 1 && store.service !== query.data.services[0].key) {
      payload.service = query.data.services[0].key
    }

    if (query.data?.resources?.length === 1 && store.resource !== query.data.resources[0].id) {
      payload.resource = query.data.resources[0].id
    }

    if (query.data?.financiers?.length === 1 && store.financier !== query.data.financiers[0].key) {
      payload.financier = query.data.financiers[0].key
    }

    const locations = Object.keys(query.data?.locations || {})

    if (locations.length === 1 && store.location !== locations[0].id) {
      payload.location = locations[0].id
    }

    if (Object.keys(payload).length > 0) {
      store.set(payload)
    }
  }, [query.data, store.financier, store.resource, store.service, store.location])

  if (query.isLoading) return (
    <Loader className="mt-5 flex flex-col items-center" />
  )

  if (query.isError || !query.data?.locations) return (
    <div className="bg-yellow-200 text-yellow-900 p-3 rounded mt-7 text-center max-w-md mx-auto">{t('error_loading_message', 'Ocurrio un error 🙁')}</div>
  )

  return (
    <div className="px-3">
      <Filters team={query.data} />
      {query.data?.message && (
        <div className="bg-yellow-100 rounded text-yellow-700 p-2 px-4 mb-3">
          {query.data.message}
        </div>
      )}
      <div className="space-y-3">
        {Object.entries(query.data.locations).filter(([locationID, location]) => !store.location || locationID === store.location).flatMap(([locationID, location]) => (
          query.data.resources.filter(resource => {
            if (store.resource && resource.id !== store.resource) return false
            if (store.financier && !resource.financiers.find(i => i.key === store.financier)) return false
            if (store.service && !resource.services.find(i => i.key === store.service)) return false
            if (!resource.locations.includes(location.id)) return false
            return true
          }).map(resource => (
            <Profile
              key={`${locationID}-${resource.id}`}
              location={location}
              resource={resource}
              team={query.data}
            />
          ))
        ))}
        {query.data.resources.filter(resource => {
          if (store.resource && resource.id !== store.resource) return false
          if (store.financier && !resource.financiers.find(i => i.key === store.financier)) return false
          if (store.service && !resource.services.find(i => i.key === store.service)) return false
          return resource.locations.length === 0
        }).map(resource => (
          <Profile
            key={`${resource.id}`}
            resource={resource}
            team={query.data}
          />
        ))}
      </div>

    </div>
  )
}

export default Home;
