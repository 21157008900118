import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

export function Payments({ params, selected, onClick }) {
  const { t } = useTranslation()
  const [show, setShow] = useState()
  const { mercadopago, cash, reservation } = params

  const Reserve = ({ type }) => {
    if (!type) return null
    let label
    let description
    if (type === 'always') {
      label = t(`pay_flow_reserve_${type}`, 'Pagá una reserva y el resto en el consultorio')
      description = t(`pay_flow_reserve_${type}_description`, 'Paga la reserva por MercadoPago. El resto lo pagarás en el consultorio.')
    } else if (type === 'optional') {
      label = t(`pay_flow_reserve_${type}`, '¡Pagá una reserva y ahorrá un 10%!')
      description = t(`pay_flow_reserve_${type}_description`, 'Ahorrá un 10% pagando una reserva por MercadoPago. El resto lo pagarás en el consultorio.')
    } else {
      return null
    }
    return (
      <button
        onClick={() => {
          onClick(`reserve-${type}`);
          setShow(false)
        }}
        className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-6"
      >
        <div className="w-[25px] min-w-[25px]">
          <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=ea580c&text=RE" />
        </div>
        <div className="text-sm grow">
          <div className="font-medium mb-1">{label}</div>
          <div>{description}</div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </button>
    )
  }

  const MP = props => {
    return (
      <button
        onClick={() => {
          onClick('mercadopago');
          setShow(false)
        }}
        className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-6"
      >
        <div className="w-[25px] min-w-[25px]">
          <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=2563eb&text=MP" />
        </div>
        <div className="text-sm grow">
          <div className="font-medium mb-1">{t('pay_flow_mp', 'Pagar por MercadoPago')}</div>
          <div>
            {t('pay_flow_mp_description', 'Pagá el 100% del valor de la consulta por MercadoPago.')}
          </div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </button>
    )
  }

  const Cash = props => {
    return (
      <button
        onClick={() => {
          onClick('offline');
          setShow(false)
        }}
        className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-6"
      >
        <div className="w-[25px] min-w-[25px]">
          <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=c026d3&text=CA" />
        </div>
        <div className="text-sm grow">
          <div className="font-medium mb-1">{t('pay_flow_cash', 'Pago en el centro')}</div>
          <div>
            {t('pay_flow_cash_description', 'Pagá el 100% del valor de la consulta directamente en el consultorio.')}
          </div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </button>
    )
  }

  useEffect(() => {
    if (!selected && params.mercadopago) {
      onClick('mercadopago')
    } else if (!selected && params.reservation === 'always') {
      onClick('reserve-always')
    } else if (!selected && params.reservation === 'optional') {
      onClick('reserve-optional')
    } else if (!selected && params.cash) {
      onClick('cash')
    }
  }, [selected, params.mercadopago, params.cash, params.reservation])

  if (!selected) return null

  if (show) return (
    <div className="
      fixed h-screen top-0 bottom-0 z-[1] w-full md:relative md:h-[auto!important]
      bg-white sm:mt-4 sm:rounded-md
    ">
      <div className="flex items-center border-b">
        <button
          className="p-4"
          onClick={() => setShow(false)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="grow text-center font-medium text-secondary text-[19px] -ml-4">{t('payment_options', 'Opciones de pago')}</div>
      </div>

      <div className="flex flex-col divide-y">
        {mercadopago && cash && reservation === "always" ? (
          <>
            <MP />
            <Reserve type={reservation} />
          </>
        ) : mercadopago && cash && reservation === "optional" ? (
          <>
            <MP />
            <Reserve type={reservation} />
            <Cash />
          </>
        ) : mercadopago && cash && reservation === "never" ? (
          <>
            <MP />
            <Cash />
          </>
        ) : mercadopago && !cash && reservation === "always" ? (
          <>
            <MP />
            <Reserve type={reservation} />
          </>
        ) : mercadopago && !cash && reservation === "optional" ? (
          <>
            <MP />
            <Reserve type={reservation} />
          </>
        ) : mercadopago && !cash && reservation === "never" ? (
          <>
            <MP />
          </>
        ) : !mercadopago && cash && reservation === "always" ? (
          <>
            <Reserve type={reservation} />
          </>
        ) : !mercadopago && cash && reservation === "optional" ? (
          <>
            <Reserve type={reservation} />
            <Cash />
          </>
        ) : !mercadopago && cash && reservation === "never" ? (
          <>
            <Cash />
          </>
        ) : !mercadopago && !cash && reservation === "always" ? (
          <>
            <Reserve type={reservation} />
          </>
        ) : !mercadopago && !cash && reservation === "optional" ? (
          <>
            <Reserve type={reservation} />
          </>
        ) : !mercadopago && !cash && reservation === "never" && (
          <>
            <Cash />
          </>
        )}
      </div>
      <div className="text-[12px] text-secondary/80 p-4 text-center bg-gray-100">
        {t('payment_method_cancel_disclaimer_1', 'Recuerda que si cancelás tu turno con 48 hs de antelación recibirás una devolución del 100% del importe abonado. Si el es dentro de las proximas 48 hs cancelalo lo antes posible')}
      </div>
    </div>
  )

  return (
    <button
      onClick={() => setShow(true)}
      className="
        bg-white mt-4 sm:rounded-md w-full flex items-center justify-between
      "
    >
      {selected === 'mercadopago' ? (
        <div className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-3 sm:px-6">
          <div className="w-[25px] min-w-[25px]">
            <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=2563eb&text=MP" />
          </div>
          <div className="text-sm grow">
            {t('pay_flow_mp', 'Pagar por MercadoPago')}
          </div>
        </div>
      ) : selected === 'cash' ? (
        <div className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-3 sm:px-6">
          <div className="w-[25px] min-w-[25px]">
            <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=c026d3&text=CA" />
          </div>
          <div className="text-sm grow">
            {t('pay_flow_cash', 'Pago en el centro')}
          </div>
        </div>
      ) : selected === 'reserve-always' ? (
        <div className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-3 sm:px-6">
          <div className="w-[25px] min-w-[25px]">
            <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=ea580c&text=RE" />
          </div>
          <div className="text-sm grow">
            {t(`pay_flow_reserve_always`, 'Pagá una reserva y el resto en el consultorio')}
          </div>
        </div>
      ) : selected === 'reserve-optional' ? (
        <div className="text-left text-secondary/80 flex items-center space-x-4 py-3 px-3 sm:px-6">
          <div className="w-[25px] min-w-[25px]">
            <img src="https://plchldr.co/i/25x25?&bg=ffffff&fc=ea580c&text=RE" />
          </div>
          <div className="text-sm grow">
            {t(`pay_flow_reserve_optional`, '¡Pagá una reserva y el resto en el consultorio!')}
          </div>
        </div>
      ) : (
        <div className="py-4 px-6">

        </div>
      )}
      <div className="text-green-600 text-sm py-4 px-6">
        {t('modify', 'Modificar')}
      </div>
    </button>
  )
}
