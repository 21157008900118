import { useTranslation } from "react-i18next";

export function Range(props) {
  const { t } = useTranslation()

  const [startsAt, endsAt] = props.range.split('-')
  const date = new Date(`${props.day}T${startsAt}`)

  return (
    <div className="flex space-x-2">
      <div>{date.toLocaleDateString([], { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}.</div>
      <div
        dangerouslySetInnerHTML={{
          __html: t('range_between', `Entre las <span class="text-black">${startsAt}</span> y las <span class="text-black">${endsAt}</span>`, { startsAt, endsAt })
        }}
      />
    </div>
  )
}
