import create from 'zustand'
import { persist } from "zustand/middleware"

const now = new Date()
let startsAt

try {
  const params = new URLSearchParams(window.location.search)
  startsAt = params.get('date.from')
} catch {}

startsAt ||= now

if (startsAt?.constructor === String) {
  startsAt = new Date(`${startsAt}T00:00`)
}

const useStore = create(persist(set => ({
    date: startsAt,
    update: payload => set(() => ({ ...payload }))
  }),
  {
    name: 'wizard',
    getStorage: () => window.sessionStorage || window.localStorage,
    deserialize: string => {
      const payload = JSON.parse(string)
      payload.state.date = startsAt
      return payload
    },
  }
))
export default useStore
