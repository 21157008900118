import { Router } from "@reach/router"

import Event from './Event'
import Checkout from './Checkout/Checkout'
import Confirmation from './Confirmation/Confirmation'
import Payment from './Payment/Payment'

const Events = () => {
  return (
    <Router>
      <Payment path=":eventID/:token/pago" />
      <Payment path=":eventID/:token/pago/:status" />
      <Confirmation path=":eventID/:token/:message" />
      <Checkout path="checkout/:code" />
      <Event path=":eventID/:token" />
    </Router>
  )
}

export default Events;
