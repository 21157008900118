import { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'

const Categories = props => {
  const { t } = useTranslation()
  const location = useLocation()
  const [option, setOption] = useState()
  const [options, setOptions] = useState([])
  const [show, setShow] = useState()

  useEffect(() => {
    const pathname = location.pathname.replace(/^.+\/turnos/,'/turnos')

    const options = Object.entries(props.tree?.childrens || {})
    let entry = options.find(entry => pathname === entry[1].slug)
    entry ||= options.find(entry => pathname.includes(entry[1].slug))

    if (entry) {
      const [key, option] = entry
      setOption({ key, ...option })
    } else if (options.length === 1) {
      const [key, option] = options[0]
      setOption({ key, ...option })
    } else {
      setOption()
    }
    setOptions(options)
  }, [props.tree, location.pathname])

  useEffect(() => {
    props.onChange(option?.slug)
  }, [option?.slug])

  if (options.filter(entry => entry[1].label).length <= 0) return null

  return (
    <>
      <div className="w-full">
        <button
          onClick={() => setShow(!show)}
          className="
            group relative
            border border-gray-300/80 py-3 px-6 rounded-[13px] w-full text-left
            hover:border-primary
          "
        >
          <div className={`
            text-[13px] absolute -top-[12px] left-4 rounded-lg bg-white text-secondary/75 border border-transparent
          `}>{props.tree?.level ? t(props.tree.level.key, props.tree.level.label) : t('category', 'Category')}</div>
          <div className="font-medium group-hover:text-primary">
            {
              option?.label ||
              (props.tree?.level ? t(`select_${props.tree.level.key}`) : t('select_option', 'Seleccione una opcion'))
            }
          </div>
          {option?.subtitle && (
            <div className="text-xs font-light">
              {option.subtitle}
            </div>
          )}
        </button>
        {show && (
          <div className="flex flex-col -mt-[1px] mx-3 border rounded">
            {options.map(([key, child]) => (
              <button
                className={`
                  text-left border-b p-3 flex items-center justify-between
                  ${option?.key === key ? 'bg-gray-50' : ''}
                `}
                key={key}
                onClick={() => {
                  setOption({ key, ...child })
                  setShow(false)
                  props.onChange(child.slug)
                  props.onSelection((child?.childrens || []).length === 0)
                }}

              >
                <div>
                  <div>{child.label}</div>
                  {child?.subtitle && (
                    <div className="text-xs font-light">
                      {child.subtitle}
                    </div>
                  )}
                </div>
                {option?.key === key && (
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
      </div>

      <Categories
        tree={option}
        onChange={props.onChange}
        onSelection={props.onSelection}
      />
    </>
  )
}

export default Categories
