import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from '@reach/router'
import { useQuery, useMutation } from 'react-query'
import helpers from '@drapp/helpers'

import decode from '../../services/decode'
import api from '../../services/api'
import Loader from '../../Loader'
import { Payments } from './Payments'
import { Range } from './Range'

const Checkout = (props) => {
  const [params, setParams] = useState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [location, setLocation] = useState()
  const [resource, setResource] = useState()
  const [service, setService] = useState()
  const [financier, setFinancier] = useState()
  const [consumer, setConsumer] = useState({})
  const [gateway, setGateway] = useState()
  const [dirty, setDirty] = useState(false)
  const [show, setShow] = useState({})
  const [code, setCode] = useState({ id: '' })
  const [price, setPrice] = useState()
  const [free, setFree] = useState()

  const team = useQuery(
    params?.teamID || '',
    () => api(`/${params.teamID}`),
    { enabled: !!params?.teamID }
  )

  const promo = useQuery(
    code.id,
    () => api(`/cupons/${code.id}`),
    { enabled: false }
  )

  const mutation = useMutation(
    payload => api(`/${team.data?.id}/events`, payload),
    {
      onMutate: (data) => {
        try {
          window.gtag("event", "purchase", {
            currency: data?.currency,
            value: data?.price?.total || 0,
            discount: data?.price?.discount || 0,
            resource: data?.resource?.id,
            day: data?.day,
            time: data?.time,
            range: data?.range,
          });
        } catch {}
      },
      onSuccess: (data) => {
        if (data.mercadopago?.preference?.id) {
          navigate(`/turnos/${data.id.replace('events/', '')}/${data.token}/pago`)
        } else {
          navigate(`/turnos/${data.id.replace('events/', '')}/${data.token}/gracias`)
        }
      },
      onError: () => {}
    }
  )

  const availability = useQuery(
    ['availability/free', params?.teamID, params?.resourceID, params?.serviceKey],
    () => api('/availability', {
      team: params?.teamID,
      resource: params?.resourceID,
      service: params?.serviceKey || undefined,
      financier: params?.financierKey || undefined,
      startsAt: new Date(`${params?.day}T00:00`).getTime(),
      price: 'cartilla',
      marketplace: 'cartilla',
      visibility: 'unlisted',
      valid: "cartilla"
    }), {
      enabled: !!(params && params.resourceID  && params.day && params.time),
      refetchInterval: 60_000,
      refetchOnWindowFocus: "always",
      onSuccess: data => {
        let free = false
        if(data && data.slots) {
          free = !!data.slots[params.day][params.time]
        } else if(data && data.ranges){
          free = !!data.ranges[params.day][params.time]
        }
        setFree(free)
        return data
      }
    }
  )

  const onChangeConsumer = ({ target: { name, value } }) => {
    setConsumer({
      ...consumer,
      [name]: value
    })
  }

  const onChangeGateway = method => {
    setGateway(method)
  }

  const save = async () => {
    if (!team.data?.id) return
    if (mutation.isLoading) return
    if (!dirty) setDirty(true)
    if (!consumer.identification || consumer.identification.toString().trim().length < 6) return
    if (!consumer.phone || consumer.phone.toString().trim().length < 6) return
    if (!consumer.lastName || consumer.lastName.toString().trim().length < 3) return
    if (!consumer.firstName || consumer.firstName.toString().trim().length < 3) return
    if (!helpers.isEmail(consumer.email)) return
    if (!params.range) {
      const { data } = await availability.refetch({ cancelRefetch: true })
      const free = !!data?.slots?.[params.day]?.[params.time]
      if (!free) return window.alert(t(`slot_bussy`, 'El turno ya no se encuentra disponible'))
    }
    const payload = {
      source: params?.source || 'wiri',
      resource: { id: resource?.id, label: resource.label },
      location: { id: location?.id },
      service: { key: service?.key, label: service?.label },
      financier: {
        key: financier?.key || 'pms_financiers:wiri',
        label: financier?.label || 'Wiri Salud',
        identification: consumer.financier
      },
      consumer: { ...consumer },
      pricing: price,
      day: params.day,
      time: params.time,
      range: params.range,
      flow: params.flow || undefined,
      whatsapp: params.whatsapp || undefined,
    }
    if (payload.pricing) {
      payload.pricing.gateway = gateway
    }

    if (payload.pricing && gateway === 'reserve-always') {
      payload.pricing.reservation = 'always'
    } else if (payload.pricing && gateway === 'reserve-optional') {
      payload.pricing.reservation = 'optional'
    }

    if (payload.pricing && !payload.pricing.reservation) {
      payload.pricing.reservation = params.reservation
    }

    setDirty(false)
    mutation.mutate(payload)
  }

  useEffect(() => {
    try {
      const params = decode(props.code)

      if (params.day && params.time) {
        params.datetime = new Date(`${params.day}T${params.time}`)
      }

      params.price = Number(params.price) || undefined

      setParams(params)
    } catch {}
  }, [props.code])

  useEffect(() => {
    let resource
    let service
    let financier
    let location
    try {
      if (team.data?.resources && params.resourceID) {
        resource = team.data.resources.find(resource => params.resourceID === resource.id)
        service = resource.services.find(service => service.key === params.serviceKey)
        financier = resource.financiers.find(financier => financier.key === params.financierKey)
        financier ||= resource.financiers.find(financier => financier.key === `${params.financierKey}/`)
        location = team.data.locations[params.locationID]
      }
    } catch {}
    setLocation(location)
    setResource(resource)
    setService(service)
    setFinancier(financier)
  }, [
    team.data?.locations,
    team.data?.resources,
    params?.financierKey,
    params?.locationID,
    params?.resourceID,
    params?.serviceKey,

  ])

  useEffect(() => {
    try {
      let service = params.price
      let factor = 0.01
      if (/wiri/ig.test(params.financierKey)) {
        factor = 0.10
      }
      let fee = params.price * factor
      let total = params.price + fee

      let price = {
        service,
        fee,
        total
      }

      let discounts = []

      if (promo.data?.id && !promo.data?.fixed && total) {
        const data = { ...promo.data }
        // factor: fija un porcentaje
        if (data.factor) {
          discounts.push(params.price * data.factor)
        }
        // amout: fija el descuento
        if (data.amount) {
          discounts.push(data.amount) 
        }
        if (data.limit) {
          discounts = discounts.filter(value => value <= data.limit)
        }
      }

      let discount = discounts.sort().reverse()[0] || 0
      if (discount > service) {
        discount = service
      }

      // fixed: fija el total
      if (promo.data?.fixed && total) {
        total = promo.data.fixed + fee
      } else if (total) {
        total = params.price - discount + fee
      }

      price = { ...price, discount, total }

      if (promo.data?.id && total) {
        setCode({ ...promo.data, discount })
        price.promo = { ...promo.data }
      }

      setPrice(price)
    } catch {}
  }, [promo.data, params?.price, gateway])

  if (!team.data?.resources || !resource || !service || !location) return (
    <div className="h-screen flex justify-center items-center">
      <Loader />
    </div>
  )

  let mapLink
  const coords = [location?.place.geo?.location?.lat, location?.place.geo?.location?.lng].filter(Boolean)
  if (location?.place?.place_id && coords.length === 2) {
    mapLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.address)}&destination_place_id=${location?.place?.place_id}`
  } else if (coords.length === 2) {
    mapLink = `https://www.google.com/maps/search/?api=1&query=${coords.join(",")}`
  }

  const invalid = {}
  invalid.firstName = dirty && (!consumer.firstName || consumer.firstName.toString().trim().length < 3)
  invalid.lastName = dirty && (!consumer.lastName || consumer.lastName.toString().trim().length < 3)
  invalid.identification = dirty && (!consumer.identification || consumer.identification.toString().trim().length < 6)
  invalid.phone = dirty && (!consumer.phone || consumer.phone.toString().trim().length < 6)
  invalid.email = dirty && !helpers.isEmail(consumer.email)
  invalid.financier = dirty && financier && !/private|wiri/.test(financier.key) && (!consumer.financier || consumer.financier.toString().length < 5)

  const format = value => {
    const currency = t(`currency_symbol_${params.currency}`, '$')
    const string = value?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    return `${currency}${string}`
  }

  if (availability.isLoading || (free === undefined && !params.range)) return (
    <div className="h-screen flex items-center justify-center space-x-4 mb-4">
      <Loader className="flex flex-col items-center" />
    </div>

  )

  if (params && !params.range && !free && !availability.isLoading) return (
    <div className="h-screen">
      <div className="bg-yellow-200 p-3 md:p-6 sm:rounded-md md:mt-2">
        <div className="center text-2xl">{t('slot_bussy', 'El turno ya no esta disponible')}</div>
        <button onClick={() => navigate(-1, { replace: true })} className="mt-3">{t('back', 'Volver')}</button>
      </div>
    </div>
  )

  return (
    <div className="h-screen">
      <div className="bg-white p-3 md:p-6 sm:rounded-md md:mt-2">
        <div className="flex items-center space-x-4 mb-4">
          <div>
            <div
              className="bg-gray-200"
              style={{
                borderRadius: '20px',
                backgroundImage: resource.image ? `url(${resource.image})` : undefined,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: 67,
                height: 77,
              }}
            />
          </div>
          <div>
            <div className="text-secondary font-medium">{resource.label}</div>
            <div className="text-sm opacity-75">{location.address}</div>
            {mapLink && (
              <div className="text-xs">
                <a href={mapLink} target="_blank" rel="noreferrer" className="text-blue-600 inline-flex items-center whitespace-nowrap">
                  {t('directions', 'Como llegar')}
                </a>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="sm:flex sm:items-center sm:justify-between sm:space-x-2 text-sm mb-1">
            <div className="hidden sm:block font-medium text-secondary">{t('service', 'Servicio')}</div>
            <div>{t(service.key, service.label)}</div>
          </div>
          <div className="sm:flex sm:items-center sm:justify-between sm:space-x-2 text-sm">
            <div className="hidden sm:block font-medium text-secondary">{t('date', 'Fecha')}</div>
            {params.datetime ? (
              <div>{new Date(params.datetime).toLocaleDateString([], { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' })}</div>
            ) : !!params.range && (
              <Range range={params.range} day={params.day} />
            )}
          </div>
        </div>
      </div>

      {!!price?.service && (
        <div className="bg-white p-3 md:p-6 mt-5 sm:rounded-md flex flex-col space-y-2">
          <div className="flex items-center justify-between space-x-2 text-sm">
            <div className="text-secondary">{t(service.key, service.label)}</div>
            <div>{format(price.service)}</div>
          </div>
          {!!price.discount && (
            <div className="flex items-center justify-between space-x-2 text-sm">
              <div className="text-secondary">{t('promo_discount', 'Descuento promocional')}</div>
              <div>-{format(price.discount)}</div>
            </div>
          )}
          <div className="flex items-center justify-between space-x-2 text-sm">
            <div className="text-secondary">{t('service_charge', 'Cargo de gestión')}</div>
            <div>{format(price.fee)}</div>
          </div>
          <div className="flex items-center justify-between space-x-2 text-sm border-t pt-2">
            <div className="font-medium text-secondary">{t('total', 'Total')}</div>
            <div className="text-green-600 font-medium">
              {format(price.total)}
            </div>
          </div>
        </div>
      )}

      {!!price?.service && (
        <Payments
          params={params}
          selected={gateway}
          onClick={onChangeGateway}
        />
      )}

      {code.id && code.id === promo.data?.id && promo.isFetched ? (
        <div className="bg-white py-4 px-3 sm:px-6 mt-4 flex justify-center items-center space-x-3 sm:rounded-md">
          <div className="grow">{code.id}</div>
          <div
            className={`bg-white py-2 text-green-600 capitalize`}
          >
            {t('applyed', 'aplicado')}
          </div>
        </div>
      ) : params.price && !!show.code && (
        <div className="bg-white py-4 px-3 sm:px-6 mt-4 flex justify-between items-end space-x-3 sm:rounded-md">
          <div className="grow">
            <label className={`text-sm ml-1 block font-medium text-first-up`}>{t('add_promo_code_label', 'Ingresá el código promocional')}</label>
            <input
              type="text"
              name="code"
              value={code.id || ''}
              onChange={e => setCode({ id: e.target.value })}
              placeholder=""
              className={`
                w-full rounded-lg border border-gray-300 p-2
                focus:outline-none focus:border-blue-400 focus:outline-none
                active:outline-none placeholder-gray-400
                ${promo.isFetching ? 'opacity-75 bg-gray-100' : ''}
              `} />
          </div>
          <button
            onClick={() => promo.refetch({ cancelRefetch: true })}
            disabled={(code.id?.length || 0) < 5}
            className={`
              bg-white py-2 px-4 rounded-md flex items-center
              text-white/90 ${(code.id?.length || 0) >= 5 ? 'bg-primary/90 hover:bg-primary hover:text-white' : 'bg-gray-400/75'}
              ${promo.isFetching ? 'opacity-75' : ''}
            `}
          >
            {promo.isFetching && (
              <Loader className="mr-2" color="white" />
            )}

            {t('apply', 'Aplicar')}
          </button>
          <button
            onClick={() => setShow({ ...show, code: false })}
            className={`
              bg-white py-2 pl-2 rounded-md
              hover:text-black
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      )}

      {params.price && !show.code && (
        <button
          onClick={() => setShow({ ...show, code: true })}
          className="
            w-full bg-white py-4 px-3 sm:px-6 mt-4 sm:rounded-md text-left font-medium flex justify-between items-center
          "
        >
          <div>
            {t('add_promo_code_title', 'Añade tu código promocional')}
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      )}

      <div className="bg-white p-3 md:p-6 mt-4 sm:rounded-md">
        <div className="text-lg text-black mb-2">
          {t('consumer_information_title', 'Datos del paciente', { context: 'checkout' })}
        </div>

        <div className={`
          grid ${financier && !/private|wiri/.test(financier.key) ? 'grid-rows-4 md:grid-rows-2' : 'grid-rows-3 md:grid-rows-1'} md:grid-flow-col gap-4 lg:gap-8
        `}>
          <div>
            <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.firstName ? 'text-red-600' : ''}`}>{t('firstName', 'Nombre')}</label>
            <input
              type="text"
              name="firstName"
              value={consumer.firstName || ''}
              onChange={onChangeConsumer}
              placeholder=""
              className={`w-full rounded-lg border ${invalid.firstName ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
          </div>

          <div>
            <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.lastName ? 'text-red-600' : ''}`}>{t('lastName', 'apellido')}</label>
            <input
              type="text"
              name="lastName"
              value={consumer.lastName || ''}
              onChange={onChangeConsumer}
              placeholder=""
              className={`w-full rounded-lg border ${invalid.lastName ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
          </div>

          <div>
            <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.identification ? 'text-red-600' : ''}`}>{t('identification', 'Nro. documento')}</label>
            <input
              inputMode="numeric"
              pattern="[0-9]*"
              type="number"
              name="identification"
              value={consumer.identification || ''}
              onChange={onChangeConsumer}
              placeholder=""
              className={`w-full rounded-lg border ${invalid.identification ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
          </div>

          {financier && !/private|wiri/.test(financier.key) && (
            <div>
              <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.financier ? 'text-red-600' : ''}`}>{t('financier_identification', `N° afiliado ${t(financier.key, financier.label)}`, { financier: t(financier.key) })}</label>
              <input
                type="text"
                name="financier"
                value={consumer.financier || ''}
                onChange={onChangeConsumer}
                placeholder=""
                className={`w-full rounded-lg border ${invalid.financier ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
            </div>
          )}
        </div>

        <div className="text-lg text-black mt-6 mb-2">
          {t('event_notification_title', '¿A dónde te avisamos?', { context: 'checkout' })}
        </div>
        <div className="grid grid-rows-2 md:grid-rows-1 md:grid-flow-col gap-4 lg:gap-8">
          <div>
            <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.email ? 'text-red-600' : ''}`}>{t('email', 'Correo electronico')}</label>
            <input
              type="email"
              name="email"
              value={consumer.email || ''}
              onChange={onChangeConsumer}
              placeholder=""
              className={`w-full rounded-lg border ${invalid.email ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
          </div>

          <div>
            <label className={`text-sm ml-1 block font-medium text-first-up ${invalid.phone ? 'text-red-600' : ''}`}>{t('cellphone_number', 'Número de celular')}</label>
            <input
              type="text"
              inputMode="numeric"
              pattern="[0-9+ -]*"
              name="phone"
              value={consumer.phone || ''}
              onChange={onChangeConsumer}
              placeholder=""
              className={`w-full rounded-lg border ${invalid.phone ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-400 focus:outline-none active:outline-none placeholder-gray-400`} />
          </div>
        </div>
      </div>

      {Object.values(invalid).some(Boolean) && (
        <div className="bg-red-100 text-red-700 text-lg p-4 rounded-md mb-5">{t('check_highlighted_fields', 'Verifique los campos en rojo')}</div>
      )}

      <div className="flex justify-end bg-white p-3 md:p-6 mt-5 sm:rounded-md sticky bottom-0" style={{ boxShadow: '-0 -3px 12px rgba(0, 0, 0, 0.05)' }}>
        <button
          tabIndex={-1}
          onClick={() => navigate(-1)}
          className="py-2 px-4 mr-4 rounded-md text-gray-400 capitalize"
        >{t('cancel', { label: t('event') })}</button>
        <button
          className={`
            text-white/90 bg-primary/90 py-2 px-4 rounded-md flex items-center ${mutation.isLoading ? 'space-x-2 text-opacity-50' : ''}
            hover:bg-primary hover:text-white
          `}
          onClick={save}
        >
          {mutation.isLoading && <Loader className="flex flex-col items-center" color="white" />}
          <div className="capitalize">{t('confirm', 'confirmar')}</div>
        </button>
      </div>
    </div>
  )
}

export default Checkout
