import { useQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'

import Slot from './Slot'
import Range from './Range'

import api from '../../../services/api'

const Day = props => {
  const { ref, inView } = useInView();

  const query = useQuery(
    ['availability/query', props.filters],
    () => api('/availability', props.filters), {
      enabled: !!inView,
      refetchInterval: 60_000,
      refetchOnWindowFocus: "always",
      onSettled: data => props.onSuccess(data),
      onError: () => {}
    }
  )

  const ranges = Object.entries(query.data?.ranges || {})
  const slots = Object.entries(query.data?.slots || {})

  return (
    <div ref={ref}>
      {ranges.length > 0 ? ranges.map(([day, slots]) => (
        <div key={day}>
          <div className="ml-1 -mb-2 text-[13px] text-secondary font-medium">
            {new Date(`${day}T00:00`).toLocaleDateString([], { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}
          </div>
          <div className="overflow-x-auto flex gap-x-3 py-3">
            {Object.entries(slots).map(([range, slot]) => (
              <Range
                key={range}
                slot={{
                  ...slot,
                  ...props.filters,
                  price: props.price,
                  currency: props.currency,
                  day,
                  range,
                }}
              />
            ))}
          </div>
        </div>
      )) : (slots.map(([day, slots]) => (
        <div key={day}>
          <div className="ml-1 -mb-2 text-[13px] text-secondary font-medium">
            {new Date(`${day}T00:00`).toLocaleDateString([], { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}
          </div>
          <div className="overflow-x-auto flex gap-x-3 py-3">
            {Object.entries(slots).map(([time, slot]) => (
              <Slot
                key={time}
                slot={{
                  ...slot,
                  ...props.filters,
                  price: props.price,
                  currency: props.currency,
                  day,
                  time,
                }}
              />
            ))}
          </div>
        </div>
        ))
      )}
    </div>
  )
}

export default Day
