import { useState, useEffect } from 'react'
import { DayPicker } from 'react-day-picker'
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from '@reach/router'
import { Picker } from '../../Picker/Picker'

import Filters from './Filters/Filters'
import useStore from './useStore'

const locales = { es, en }

const Item = props => {
  const { i18n, t } = useTranslation()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const id = props.financier.id.replace('pms_financiers:', '')

  params.set('financier', id)

  return (
    <Link
      to={`${location.pathname}?${params.toString()}`}
      onClick={() => props.onClick(id)}
      className={`
        w-full text-left py-3 px-4 border-b
        ${id.includes('wiri') ? 'text-primary' : ''}
      `}
    >
      {i18n.financier(props.financier.id, props.financier.label)}
    </Link>
  )
}

const Header = props => {
  const [filters, showFilters] = useState()
  const { i18n, t } = useTranslation()
  const [financiers, showFinanciers] = useState(false)
  const store = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const locale = locales[i18n.language?.split('-')?.[0]] || locales.es
  const [params, setParams] = useState(new URLSearchParams(location.search))
  const [financier, setFinancier] = useState(params.get('financier') || 'wiri')

  const onDayClick = date => {
    store.update({ date })

    const params = new URLSearchParams(location.search)
    params.delete('date.from')
    params.delete('date.until')

    params.set('date.from', date.toLocaleDateString('fr-ca'))

    navigate(`${location.pathname}?${params.toString()}`)
  }

  useEffect(() => {
    setParams(new URLSearchParams(location.search))
  }, [location.search])

  return (
    <>
      {filters && (
        <Filters
          onClose={() => showFilters(false)}
        />
      )}
      <div className="bg-white p-4 md:mb-4 md:sticky md:top-0 md:z-[1] md:shadow-sm sm:rounded-lg">
        <button
          onClick={() => showFilters(true)}
          className="
            group
            text-left
            w-full flex bg-gray-100 px-3 py-2 rounded-lg justify-between items-center
            hover:bg-primary/10 active:bg-primary/20
          "
        >
          <div>
            <div className="leading-none">
              {props.labels?.filter(Boolean)?.map((part, index) =>
                <span key={part}
                  className={`text-sm leading-tight ${index === 0 ? 'text-primary' : ''}`}
                > {(index > 0 ? `- ${part}` : part)?.trim()} </span>
              )}
            </div>
            <div className="flex gap-x-1">
              <div className={`text-xs leading-tight text-ellipsis overflow-hidden text-left opacity-80`}>
                {props.subtitle}
              </div>
            </div>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-1 opacity-60 h-6 w-6 group-hover:opacity-100 group-hover:text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
          </div>
        </button>
        <div className="mt-4 flex items-center gap-x-2">
          <div>
            <button
              className="rounded-[18px] text-ellipsis overflow-hidden whitespace-nowrap border border-[#70707026] px-3 py-1 hover:bg-gray-50 active:bg-gray-100"
              onClick={() => showFilters(true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-1 h-5 w-5 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
              </svg>
            </button>
          </div>
          <div className="relative">
            <Picker
              label={t('date', 'Fecha')}
              date={store.date}
              onDayClick={onDayClick}
              className="
                bg-white z-[1]
                fixed top-0 left-0 bottom-0 left-0 w-full flex flex-col pt-10
                md:absolute md:top-auto md:left-auto md:bottom-auto md:left-auto md:w-auto md:pt-4 md:p-4 md:mt-1 md:border md:border-gray-300 md:rounded md:shadow-lg
                md:-left-10
              "
            />
          </div>
          <div className="relative">
            <button
              onClick={() => showFinanciers(!financiers)}
              className="
                text-[13px] rounded-[18px] text-ellipsis overflow-hidden whitespace-nowrap border border-[#70707026] px-3 py-1 hover:bg-gray-50 active:bg-gray-100
              "
            >
              <span className="font-medium hidden sm:inline">{t('financier', 'Cobertura')}</span> <span>{i18n.financier(financier)}</span>
            </button>
            {financiers && (
              <div
                className="
                  bg-white z-[1]
                  fixed top-0 left-0 bottom-0 left-0 w-full items-center flex flex-col overflow-auto
                  md:absolute md:top-auto md:left-auto md:bottom-auto md:left-auto md:w-auto md:pt-0 items-center md:mt-1 md:border md:border-gray-300 md:rounded md:shadow-lg
                  md:border-b-0 md:max-h-[300px] md:min-w-[250px] md:-left-10
                "
              >
                <div className="md:hidden text-lg font-medium border-b p-3 w-full text-center">{t('financier_plural', 'Coberturas')}</div>
                {props.financiers.map(financier => (
                  <Item
                    key={financier.id}
                    financier={financier}
                    onClick={id => {
                      setFinancier(id)
                      showFinanciers(false)
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
