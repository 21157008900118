import { useState, useEffect, useMemo } from 'react'
import { useLocation, Link } from '@reach/router'
import { useTranslation } from 'react-i18next'

import useCMS from '../useCMS'
import useStore from '../useStore'
import Categories from './Categories'
import FiltersZones from './FiltersZones'

const Filters = props => {
  const [option, setOption] = useState()
  const cms = useCMS()
  const location = useLocation()
  const [category, setCategory] = useState()
  const [, , type] = useStore(state => [state.address, state.coords, state.type])  
  const [isValid, setIsValid] = useState(false)
  const [selectedZone, setSelectedZone] = useState()

  const { t } = useTranslation()

  useEffect(() => {
    const current = cms?.categories?.current || {}
    const key = current.slug?.split('/')?.filter(Boolean)?.[0]
    const child = cms?.categories?.childrens?.[key]
    setOption({ key, ...child })
  }, [cms])

  const categories = Object.entries(cms?.categories?.childrens || {})

  const showResultsLink = useMemo(() => {
    let links = ['input', 'current']?.includes(type) ? (
      [cms?.country?.slug, `/${type}`, category || cms?.categories?.current?.slug].join('')
    ) : (
      [selectedZone?.slug || cms?.zones?.current?.slug, category || cms?.categories?.current?.slug].join('')
    )
  
    const params = new URLSearchParams(location.search).toString()

    if (params) {
      links = `${links}?${params}`
    }    

    return links
  }, [category, location.search, type, selectedZone, cms])

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 w-full h-full bg-black/50 z-[2] overflow-y-auto">
      <div className="max-w-6xl mx-auto min-h-screen flex flex-col">
        <div className="bg-white md:mx-4 p-4 md:p-8 md:rounded-md">
          <div className="flex items-center relative">
            <button
              onClick={props.onClose}
              className="absolute top-0 left-0"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="text-[19px] grow text-center font-medium">{t('edit_your_search', 'Editá tu búsqueda')}</div>
          </div>
          <div className="flex items-center justify-center py-3 gap-x-2 mt-6 mb-2 overflow-x-auto">
            {categories.map(([key, child]) => (
              <div key={key}>
                <button
                  onClick={() => setOption({ key, ...child })}
                  className={`flex items-center text-[13px] rounded-lg px-4 py-1 border text-primary bg-indigo-100/70 hover:border-indigo-700/50 active:border-indigo-700 whitespace-nowrap
                    ${option?.key === key ? 'border-indigo-700/50' : ''}
                  `}
                >
                  {option?.key === key && (
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-1 h-4 w-4 text-gray-500/80 mr-1 -ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  )}
                  {child.label}
                </button>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center gap-y-6 lg:gap-y-8 w-full sm:max-w-md sm:mx-auto">
            <Categories
              tree={option}
              onChange={setCategory}
              onSelection={(isDirty) => setIsValid(isDirty)}
            />

            <FiltersZones
              className="w-full -mt-4 bg-gray-50 p-3 rounded-lg"
              zoneState={{selectedZone, setSelectedZone, setIsValid, category}}
            />

            <Link
              to={showResultsLink}
              replace={['input', 'current']?.includes(type)}
              onClick={() => props.onClose()}
              className={`text-center text-white font-medium rounded-[30px] py-4 px-8 w-full hover:bg-indigo-600 active:bg-primary ${!isValid ? 'pointer-events-none bg-indigo-200' : 'bg-primary'}`}
            >
              {t('update_your_search', 'Actualizar búsqueda')}
            </Link>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Filters
