import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import useCMS from './useCMS'
import Position from './Position'
import Address from './Address'

const Zones = props => {
  const [entries, setEntries] = useState([])
  const cms = useCMS()
  const [provider, setProvider] = useState("google")
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate();

  useEffect(() => {
    const hasToBypassZones = cms?.categories?.current?.slug?.includes('telemedicina');
    if(hasToBypassZones && !props?.isFiltering) {      
      const redirectPath = [cms?.country?.childrens[cms?.country?.label?.toLowerCase()]?.slug, cms.categories?.current?.slug, params ? `?${params}` : null].filter(Boolean).join('')
      navigate(redirectPath, { replace: true });
    }
    const entries = Object.values(cms?.zones?.current?.childrens || cms?.zones?.childrens?.[cms?.country?.id]?.childrens || cms?.zones?.childrens || {})
    setEntries(entries)    
  }, [cms])
  useEffect(() => {
    const fetchProvider = async () => {
      return fetch(process.env.REACT_APP_MAPS_PROVIDER).then(res => res.json()).then(rows => setProvider(rows.at(0)?.provider))
        .catch(() => null)
    };
    fetchProvider();
  }, [])

  if (!cms) return null

  const params = new URLSearchParams(location.search).toString()

  return (
    <div className={props.className || ''}>
      {props.title !== false && (
        <div className="flex items-center mb-6">
          <div
            className="text-[19px] font-medium leading-none"
            dangerouslySetInnerHTML={{
              __html: t(`location_finder_title`, `¿<span class="text-primary">Dónde</span> lo necesitás?`)
            }}
          />
        </div>
      )}

      {cms.country?.slug && (
        <div className="flex flex-col gap-y-6 mb-6">
          <Address provider={provider}/>
          <Position />
          <div
            className="text-[19px] font-medium leading-none"
            dangerouslySetInnerHTML={{
              __html: t(`location_finder_input_highlight`, `Zonas <span class="text-primary">más buscadas</span>`)
            }}
          />
        </div>
      )}
      <div className="flex flex-col space-y-4">
        {entries.map(entry => (
          <Link
            className="text-[15px] text-black"
            to={[entry.slug, cms.categories?.current?.slug, params ? `?${params}` : null].filter(Boolean).join('')}
            key={entry.slug}
          >{entry.label}</Link>
        ))}
      </div>
    </div>
  )
}

export default Zones
