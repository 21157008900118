import { useEffect, useState } from 'react'

const useTimer = secs => {
  const endsAt = Date.now() + (secs * 1000)

  const [state, update] = useState(secs)

  useEffect(() => {
    const interval = setInterval(() => {
      const secs = Math.round((endsAt - Date.now()) / 1000)
      update(secs)
      if (secs <= 0) return clearInterval(interval)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return state
}

export default useTimer


// import { useEffect, useRef, useState } from 'react'

// const useTimer = secs => {
//   const [state, update] = useState(secs)
//   const interval = useRef(null)

//   useEffect(() => {
//     const endsAt = Date.now() + (secs * 1000)
//     interval.current = setInterval(() => {
//       const secs = Math.round((endsAt - Date.now()) / 1000)
//       update(secs)
//       if (secs <= 0) return clearInterval(interval.current)
//     }, 1000)

//     return () => {
//       if(!!interval.current) 
//         clearInterval(interval.current)
//         interval.current = null
//     }
//   }, [secs])

//   return state
// }

// export default useTimer
