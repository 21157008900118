import create from 'zustand'
import { persist } from "zustand/middleware"

const useStore = create(persist(set => ({
    landing: window.location.pathname,
    set: payload => set(state => ({ ...state, ...payload }))
  }),
  {
    name: "global",
    getStorage: () => window.sessionStorage || window.localStorage
  }
))

export default useStore
