import { useState, useEffect } from 'react'
import { Link } from '@reach/router'

import encode from '../../../services/encode'
import useStore from './useStore'

const Slot = props => {
  const update = useStore(state => state.update)
  const [link, setLink] = useState()

  const track = e => {
    try {
      window.gtag("event", "slot", {
        currency: props.slot?.currency,
        value: props.slot?.price,
        resource: props.slot?.resource,
        day: props.slot?.day,
        time: props.slot?.time,
        flow: props.slot?.flow,
      });
    } catch {}
  }

  useEffect(() => {
    let timezone
    if (props.slot) try {
      const offset = props.slot.offset
      const diff = offset !== (new Date().getTimezoneOffset() * -1)
      if (diff) {
        timezone = 'local'
      }
    } catch {}

    const payload = {
      teamID: props.slot.team,
      locationID: props.slot.location,
      resourceID: props.slot.resource,
      serviceKey: props.slot.service,
      financierKey: props.slot.financier,
      day: props.slot.day,
      time: props.slot.time,
      price: props.slot.price,
      currency: props.slot.currency,
      flow: props.slot.flow,
      whatsapp: props.slot.whatsapp,
      reservation: props.slot.reservation || 'always',
      mercadopago: props.slot.mercadopago ?? true,
      cash: props.slot.cash ?? true,
    }

    const code = encode(payload)

    update({ timezone })
    setLink(`/turnos/checkout/${code}`)
  }, [props.slot])


  if (!props.slot.time || !link) return null

  return (
    <Link
      to={link}
      onClick={track}
      className="
        text-sm opacity-80 rounded-lg border border-gray-300 font-medium px-3 py-2 leading-none
        hover:text-green-600 hover:bg-green-50 hover:border hover:border-green-200
        active:bg-green-50 active:text-green-600 active:border-green-200
      "
    >{props.slot.time}</Link>
  )
}

export default Slot
